const fr = {
    you_are_logout: "Vous avez été déconnecté",
    maintenance: {
        heading: "Désolé, Bizyness connaît quelques difficultés",
        subheading: "Nous faisons le nécessaire pour rétablir le service au plus vite",
    },
    layout_setting: {
        settings: "Paramètres",
        mode: "Mode",
        contrast: "Contraste",
        layout: "Mise en page",
        stretch: "Extensible",
        stretch_tooltip: "Disponible uniquement dans les grandes résolutions > 1600px (xl)",
        presets: "Préconfigurations",
        fullscreen: "Plein écran",
        exit_fullscreen: "Quitter le mode plein écran",
    },
    version: {
        version: "Nouvelle version disponible !",
        reload: "Recharger maintenant"
    },
    data_grid_pro: {
        unsort: "Annuler le tri",
        sort_by_asc: "Trier par ordre alphabétique",
        sort_by_desc: "Trier par ordre alphabétique inverse",
        filter: "Filtre",
        hide: "Cacher",
        show_columns: "Afficher les colonnes",
        pin_to_left: "Épingler à gauche",
        pin_to_right: "Épingler à droite",
        unpin: "Détacher",
        show_all: "Afficher tout",
        hide_all: "Cacher tout",
        find_column: "Rechercher la colonne",
        column_title: "Titre de la colonne",
        columns: "Colonnes",
        operator: "Opérateur",
        value: "Évaluer",
        add_filter: "Ajouter un filtre",
        filter_value: "Valeur du filtre",
        contains: "contient",
        equals: "égales",
        starts_with: "commence avec",
        ends_with: "se termine par",
        is: "est",
        is_not: "n'est pas",
        is_after: "est après",
        is_on_or_after: "est sur ou après",
        is_before: "est avant",
        is_on_or_before: "est le ou avant",
        is_empty: "est vide",
        is_not_empty: "n'est pas vide",
        is_any_of: "est l'un des",
        and: "Et",
        or: "Ou",
        menu: "Menu",
        sort: "Trier",
        delete: "Effacer",
        no_results_found: "Aucun résultat trouvé.",
        total_rows: "Nombre total de lignes :",
        rows_selected: "lignes sélectionnées",
        row_selected: "ligne sélectionnées",
        error_occur: "Une erreur s'est produite",
        rows_per_page: "Lignes par page",
        no_rows: "Aucun résultat",
        manage_columns: "Gérer les colonnes",
    },
    nav_menu: {
        primary: {
            dashboard: "Tableau de bord",
            invoices: "Factures",
            quotes: "Devis",
            costs: "Frais",
            banks: "Banques",
            contacts: "Contacts",
            articles: "Articles",
            integrations: "Intégrations",
            reports: "Rapports",
            settings: "Paramètres",
            notifications: "Notifications",
        },
        secondary: {
            profile: "Profil",
            logout: "Se déconnecter",
        }
    },
    login: {
        page_title: "Connexion",
        form_heading: "Connexion à votre espace",
        form_subheading: "Entrez vos identifiants ci-dessous",
        email_address_input_label: "Adresse e-mail",
        password_input_label: "Mot de passe",
        account_input_label: "Compte",
        forget_password_link: "Mot de passe oublié ?",
        dont_have_account_label: "Vous n'avez pas de compte ?",
        get_started_link: "Démarrer gratuitement",
        connect_btn: "Connexion",
        users_say_about_app_heading: "Bienvenue sur Bizyness",
        confirm_heading: "Code de confirmation",
        confirm_subheading: "Pour augmenter la sécurité de votre compte, un code de confirmation a été envoyé à votre adresse e-mail. Copiez le code reçu dans le champ ci-dessous pour continuer.",
        confirm_code_input_label: "Code de confirmation",
        continue_btn: "Continuer",
        code_sent: "Code de confirmation envoyé à {{email}}",
        error: {
            email_password_incorrect: "Email ou mot de passe incorrect !",
            email_must_valid: "L'e-mail n'est pas valide",
            email_required: "L'e-mail est requis",
            password_require: "Le mot de passe est requise",
            account_require: "Un compte est requis",
            invalid_code: "Code invalide",
            code_required: "Un code de confirmation est requis",
        }
    },
    forget_password: {
        page_title: "Mot de passe oublié",
        form_heading: "Mot de passe oublié ?",
        form_sub_heading: "Entrez votre email pour recevoir un nouveau mot de passe. N'oubliez pas de vérifier votre dossier spam.",
        back_sign_in_link: "Retour",
        password_sent_alert: "Nouveau mot de passe envoyé par email",
        email_address_input_label: "Adresse e-mail",
        reset_btn: "Réinitialiser",
        error: {
            email_must_valid: "L'e-mail n'est pas valide",
            email_required: "L'e-mail est requis",
        }
    },
    register: {
        page_title: "Créer mon compte",
        form_heading: "Démarrer gratuitement",
        form_subheading: "Entrez vos identifiants ci-dessous",
        already_have_account_label: "Vous avez déjà un compte ?",
        login_link: "Connexion",
        manage_more_effectively_heading: "Gérez simplement votre comptabilité avec Bizyness",
        terms_service_link: "Conditions d'utilisation",
        privacy_policy_link: "Politique de confidentialité.",
        email_address_input_label: "Adresse e-mail",
        password_input_label: "Mot de passe",
        confirm_password_input_label: "Confirmez le mot de passe",
        by_sign_up: "En m'inscrivant, j'accepte de ",
        and: " et ",
        register_btn: "Créer mon compte",
        error: {
            email_must_valid: "L'e-mail n'est pas valide",
            email_required: "L'e-mail est requis",
            password_require: "Le mot de passe est requise",
            pass_must_match: "Les mots de passe doivent correspondre",
            c_password_require: "La confirmation du mot de passe est requise",
        }
    },
    onboarding: {
        page_title: "Onboarding",
        logout_link: "Se déconnecter",
        welcome_family_heading: "Bienvenue dans la famille Bizyness !",
        welcome_good_choice_subheading: "Vous avez fait un bon choix en rejoignant Bizyness 🎉",
        onboarding_need_info_subheading: "Nous avons besoin de quelques informations pour configurer votre compte. Pas d'inquiétude, ce sera rapide !",
        updated: "Mis à jour avec succès",
        update_error: "Échec de la mise à jour",
        already_upto_date: "Déjà à jour",
        validate_btn: "Valider",
        next_btn: "Suivant",
        previous_btn: "Précédent",
        step_identity: {
            title: "Identité",
            who_are_you_label: "Qui êtes-vous ?",
            first_name_input_label: "Prénom",
            last_name_input_label: "Nom",
            error: {
                first_name: "Le prénom est requis",
                last_name: "Le nom est requis",
            }
        },
        step_status: {
            title: "Statut",
            status_of_company_heading: "Statut de votre entreprise",
            eligible_vat_heading: "Êtes-vous redevable de la TVA ?",
            since_when_heading: "Depuis quand ?",
            liability_date_input_label: "Date d'éligibilité",
            company_status: {
                micro: "Auto-entreprise",
                ei: "EI/EIRL",
                sarl: "SARL/EURL",
                sas: "SAS/SASU",
                other: "Autre"
            },
            error: {
                status: "Le statut est requis",
                date: "La date est requise",
                invalid_date: "La date n'est pas valide",
            },
            vat_labels: {
                yes: "Oui",
                no: "Non"
            },
            when_labels: {
                company_creation: "Depuis la création de l'entreprise",
                another_date: "Depuis une autre date"
            },
        },
        step_address: {
            title: "Adresse",
            address_input_label: "Adresse",
            complementary_address_input_label: "Adresse complémentaire",
            zip_input_label: "Code postal",
            city_input_label: "Ville",
            country_input_label: "Pays",
            address_eirl: "Adresse de votre {{company}}",
            company_sm: "entreprise",
            choose_a_country: "Choisissez un pays",
            error: {
                address: "L'adresse est requise",
                city: "La ville est requise",
                zip: "Le code postal est requis",
                country: "Le pays est requis",
            }
        },
        step_company: {
            title: "Activité",
            information_of: "Informations de votre {{company}}",
            legal_name_input_label: "Raison sociale",
            share_capital_input_label: "Capital social",
            siren_input_label: "Siren",
            vat_number_heading_input_label: "Numéro TVA",
            registration_status_input_label: "Immatriculation",
            company_sm: "entreprise",
            error: {
                legal_name: "La raison sociale est requise",
                registration_type: "Statut d'enregistrement requis",
                registration_detail: "Numéro d'enregistrement requis",
                must_allowed: "La valeur doit être supérieure ou égale à {{amount}}",
                share_capital: "Capital social requis",
            },
            registration_type_labels: {
                rcs: "Ville d'immatriculation RCS",
                rm: "Numéro du département d'immatriculation RM",
                rseirl: "Ville d'immatriculation RSEIRL",
                rcs_rm: "Ville d'immatriculation RCS et Numéro du département d'immatriculation RM (séparés par une virgule)"
            },
            registration_types: {
                rcs: "RCS",
                exempted: "Dispensé",
                rm: "RM",
                rseirl: "RSEIRL",
                rcs_rm: "Double immatriculation RCS/RM"
            },
        },
        step_urssaf: {
            title: "URSSAF",
            information_of_urssaf: "Informations URSSAF",
            creation_date_input_label: "Date de création",
            frequency_tax_pay: "Fréquence des cotisations",
            tax_discharge: "Versement libératoire",
            beneficiary_acre: "Bénéficiaire de l'ACRE",
            distribution_labels: {
                monthly: "Mensuelle",
                quarterly: "Trimestriel",
            },
            tax_discharge_labels: {
                yes: "Oui",
                no: "Non"
            },
            beneficiary_labels: {
                yes: "Oui",
                no: "Non"
            },
            error: {
                date: "La date est requise",
                invalid_date: "La date n'est pas valide",
            }
        },
        step_logo: {
            title: "Logo",
            identity: "Identité",
            delete_btn: "Effacer",
            trade_name_input_label: "Nom commercial",
            email_input_label: "Email",
            footer_input_label: "Pied de page des factures",
            alert_onboarding: "Vous pourrez mettre à jour ces éléments ultérieurement afin de personnaliser les informations affichées dans vos devis et factures",
            languages: {
                french: "Français",
                english: "Anglais",
                german: "Allemand",
                italian: "Italien",
                spanish: "Espagnol"
            },
            error: {
                email: "L'email est invalide"
            }
        },
        step_summary: {
            title: "Résumé",
            recap: "Récapitulatif",
            status: "Statut",
            legal_name: "Raison sociale",
            address: "Adresse",
            registration_status: "Immatriculation",
            siren: "Siren",
            vat_number_heading: "Numéro TVA",
        },
    },
    account_suspended: {
        heading: "Votre compte est suspendu",
        description: "Vous ne pourrez pas créer de nouvelles factures ou d'autres documents. Cliquez sur le bouton pour activer votre compte.",
        activate_account_btn: "Activer mon compte"
    },
    profile: {
        account_heading: "Compte",
        general: {
            title: "Général",
            update_account_btn: "Mettre à jour",
            email_input_label: "Email",
            first_name_input_label: "Prénom",
            last_name_input_label: "Nom",
            updated: "Mis à jour avec succès",
            error: {
                first_name: "Le prénom est requis",
                last_name: "Le nom est requis",
                email_invalid: "Le courriel est invalide",
                email: "L'e-mail est requis",
            },
        },
        billing: {
            title: "Facturation",
            your_plan: "Votre plan",
            manage_my_sub: "Gérer mon abonnement",
            another_billing_provider: "Votre abonnement est géré par un service tiers, contactez-le pour plus de détails",
            subscription_status: {
                trial: "Votre essai se terminera le {{date}}",
                active: "Votre abonnement sera renouvelé le {{date}}",
                non_renewing: "Votre abonnement prendra fin le {{date}}",
                null: "",
                cancelled: "Annulé",
            },
        },
        change_password: {
            title: "Changer le mot de passe",
            updated: "Mis à jour avec succès",
            wrong_password: "Mauvais mot de passe",
            change_password_btn: "Changer le mot de passe",
            old_password_input_label: "Ancien mot de passe",
            new_password_input_label: "Nouveau mot de passe",
            confirm_new_password_input_label: "Confirmer le nouveau mot de passe",
            error: {
                old_password: "L'ancien mot de passe est requis",
                new_password: "Un nouveau mot de passe est requis",
                password_8_char: "Mot de passe doit être d'au moins 8 caractères",
                password_should_different: "Le nouveau mot de passe doit être différent de l'ancien mot de passe",
                password_must_match: "Les mots de passe doivent correspondre",
            }
        },
        team: {
            title: "Équipe",
            active_members: "Membres actifs",
            pending_invitations: "Invitations en attente",
            deleted: "Supprimé",
            updated: "Mis à jour",
            resent: "Renvoyé",
            enabled: "Activé",
            disabled: "Désactivé",
            columns: {
                username: "Nom d'utilisateur",
                email: "Email",
                access: "Rôle",
                state: "Statut",
                action: "Actions"
            },
            edit_menu: {
                edit: "Modifier",
                delete: "Supprimer",
                resend: "Renvoyer",
                enable: "Activer",
                disable: "Désactiver",
            },
            delete_popup: {
                heading: "Avertissement",
                description: "Etes-vous sûr de vouloir supprimer ce membre ?",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
            },
            status_labels: {
                enabled: "Activé",
                disabled: "Désactivé",
                pending: "En attente"
            },
            access: {
                full: "Accès complet",
                specified: "Accès restreint"
            },
            not_found: {
                heading: "Vous n'avez encore aucun membre dans l'équipe",
                subheading: "Invitez un premier membre pour lui donner accès et l'ajouter à votre équipe",
                invite_btn: "Inviter un membre"
            },
            invite_popup: {
                heading: "Inviter un membre",
                subheading: "Configurez un accès complet ou restreint à votre compte",
                first_name: "Prénom",
                last_name: "Nom",
                email: "Email",
                invited: "Invitation envoyée",
                saved: "Enregistré",
                access: {
                    full: "Accès complet",
                    specified: "Accès restreint"
                },
                error: {
                    first_name: "Le prénom est requis",
                    last_name: "Le nom de famille est requis",
                    email_must_valid: "Email invalide",
                    email_required: "L'email est requis",
                    roles: "Au moins un rôle requis"
                },
                permission_heading: "Choisissez les permissions :",
                permission_labels: {
                    features: {
                        invoices: "Factures",
                        quotes: "Devis",
                        delivery_forms: "Formulaires de livraison",
                        payments: "Paiements",
                        expenses: "Dépenses",
                        clients: "Clients",
                        suppliers: "Fournisseurs",
                        articles: "Articles",
                        banks: "Banques",
                        activities: "Activités",
                        integrations: "Intégrations",
                        notifications: "Notifications",
                        report: "Rapports",
                        expense_type: "Types de dépenses",
                    },
                    permissions: {
                        invoices_read: "Consulter",
                        invoices_write: "Créer/Modifier/Supprimer",
                        invoices_send: "Envoyer",
                        invoices_export: "Exporter",
                        quotes_read: "Consulter",
                        quotes_write: "Créer/Modifier/Supprimer",
                        quotes_send: "Envoyer",
                        quotes_bill: "Facturer",
                        delivery_forms_read: "Consulter",
                        delivery_forms_write: "Créer/Modifier/Supprimer",
                        delivery_forms_send: "Envoyer",
                        payments_read: "Consulter",
                        payments_write: "Créer/Modifier/Supprimer",
                        payments_export: "Exporter",
                        expenses_read: "Consulter",
                        expenses_write: "Créer/Modifier/Supprimer",
                        expenses_export: "Exporter",
                        clients_read: "Consulter",
                        clients_write: "Créer/Modifier/Supprimer",
                        clients_export: "Exporter",
                        suppliers_read: "Consulter",
                        suppliers_write: "Créer/Modifier/Supprimer",
                        articles_read: "Consulter",
                        articles_write: "Créer/Modifier/Supprimer",
                        articles_export: "Exporter",
                        banks_read: "Consulter",
                        banks_write: "Modifier banques/Associer transactions",
                        activities_write: "Créer/Modifier/Supprimer",
                        integrations_read: "Consulter",
                        integrations_write: "Créer/Modifier/Supprimer",
                        notifications_write: "Supprimer",
                        report_revenues: "Revenus",
                        report_taxes: "Rapport des taxes",
                        report_accounting: "Comptabilité",
                        report_ioss: "OSS/IOSS",
                        expense_types_write: "Créer/Modifier/Supprimer",
                    }
                },
                cancel_btn: "Annuler",
                invite_btn: "Inviter",
                save_btn: "Enregistrer"
            }
        },
        api_keys: {
            title: "Clés API",
            create_key: "Créer une clé",
            copied: "Copié !",
            delete_popup: {
                heading: "Attention",
                description: "Voulez-vous vraiment supprimer la clé ?",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
                api_key_deleted: "Api key deleted",
            },
            create_popup: {
                title: "Création de clé API",
                subtitle: "Autoriser les applications tierces à accéder à votre compte Bizyness",
                key_name_input_label: "Nom de la clé",
                write_permission: "Autorisation d'écriture",
                create_key: "Créer une clé",
                allow_inv_quo_cbx: "Autoriser l'application à créer des factures et des devis",
                cancel_btn: "Annuler",
                create_btn: "Créer",
                key_created: "Clé créée",
                error: {
                    name: "Le nom est requis",
                },
            },
        },
        account_deletion: {
            title: "Suppression de compte",
            heading: "Suppression de compte",
            subheading: "Cette opération est irréversible, elle entraînera la résiliation immédiate de votre abonnement ainsi que la suppression de toutes vos données.",
            delete_my_account_btn: "Supprimer mon compte",
            confirm_popup: {
                cancel_btn: "Annuler",
                delete_my_account_btn: "Supprimer mon compte",
                deleted: "Compte supprimé !",
                wrong_password: "Mauvais mot de passe",
                heading: "Suppression de compte",
                description: "Toutes les données de votre compte seront supprimées (factures, dépenses, etc.), cette opération est irréversible. Si vous souhaitez continuer, veuillez entrer votre mot de passe et confirmer.",
                confirm_your_pass: "Confirmer votre mot de passe",
                password_input_label: "Mot de passe",
            }
        },
    },
    dashboard: {
        dashboard: "Tableau de bord",
        urssaf: {
            declaration: "Déclaration",
            quarter: "Trimestre",
            to_declare_before: "A déclarer avant le",
            revenue_of_distance_sales: "Chiffre d'affaires des ventes de marchandises :",
            revenue_of_service_deliveries: "Chiffre d'affaires des prestations de service commerciales ou artisanales :",
            revenue_of_other_services: "Chiffre d'affaires des autres prestations de services :",
            estimated_taxes: "Estimation des cotisations",
        },
        monthly_report: {
            figures_of: "Chiffres de ",
            incomes: "Recettes",
            expenses: "Dépenses",
            net_margin: "Bénéfices",
            than_last_month: "par rapport au mois précédent",
        },
        yearly_report: {
            turnover: "Chiffre d'affaires",
            than_previous_year: "par rapport à l'année précédente",
            micro_threshold: "Seuil Micro",
            vat_threshold: "Seuil de TVA",
            incomes: "Recettes",
            expenses: "Dépenses",
            net_margin: "Bénéfices",
            services: "Prestations de services",
        },
        summary: {
            overdue_invoices: "Factures en retard",
            pending_invoices: "Factures en cours",
            pending_quotes: "Devis en cours",
            no_pending_invoices: "Vous n'avez pas de facture en cours",
            no_overdue_invoices: "Vous n'avez pas de facture en retard",
            no_pending_quotes: "Vous n'avez pas de devis en cours",
            see_more: "Voir détail",
            invoices: "Factures",
            quotes: "Devis",
        },
        welcome: {
            welcome: "Accueil",
            benefits: "Bénéfices",
            bank_transactions: "Opérations bancaires",
            no_more_records: "Plus d'enregistrements !",
            see_all: "Voir tout",
        },
    },
    invoices: {
        nav_title: "Factures",
        list: {
            heading: "Factures",
            import_btn: "Importer",
            new_invoice_btn: "Nouvelle facture",
            export_popup: {
                error: {
                    invalid_email: "L'email est invalide",
                    email: "L'e-mail est requis",
                },
                export: "Exporter",
                export_msg: "L'export sera envoyé dans votre boîte e-mail dans quelques instants",
                export_as_pdf: "Format PDF",
                export_as_csv: "Format CSV",
                heading: "Exporter des factures",
                sub_heading: "Ajustez les critères pour télécharger vos factures",
                export_x_invoice: "Exporter {{count}} factures",
                date_filters: {
                    today: "Aujourd'hui",
                    yesterday: "Hier",
                    this_week: "Cette semaine",
                    last_week: "La semaine dernière",
                    this_month: "Ce mois",
                    last_month: "Le mois dernier",
                    last_30_days: "Les 30 derniers jours",
                    last_90_days: "90 derniers jours",
                    last_12_months: "12 derniers mois",
                    this_year: "Cette année",
                    last_year: "L'année dernière",
                },
                reference_date_options: {
                    date_of_issue: "Date d'émission",
                    date_of_sale: "Date de vente",
                    payment_date: "Date de paiement",
                },
                billing_period: "Période de facturation",
                more_filters: "Plus de filtres",
                less_filters: "Moins de filtres",
                from_input_label: "Du",
                to_input_label: "Au",
                loading: "Chargement...",
                not_found: "Aucun pays trouvé",
                country_input_label: "Pays",
                no_activity_found: "Aucune activité trouvée",
                activity_input_label: "Activité",
                filter_by: "Filtrer par",
                attachments: "Pièces jointes",
                include_attachments: "Inclure les pièces jointes",
                recipient: "Destinataire",
                email_input_label: "Email",
                cancel_btn: "Annuler",
                export_limit_alert: "Vous ne pouvez pas exporter plus de {{limit}} éléments à la fois, veuillez ajuster les filtres et la période",
                pdf_merged_label: "Regrouper les factures dans un seul fichier",
            },
            import_popup: {
                success: "Succès",
                heading: "Importer des factures",
                sub_heading: "Sélectionnez votre fichier contenant la liste des factures à importer",
                validate_btn: "Valider",
                import_alert: "Pour en savoir plus sur les étapes à suivre, veuillez lire cet article :",
                cancel_btn: "Annuler",
                drop_csv_here: "Déposez votre fichier CSV ici",
                create_drafts: "Créer des brouillons",
                price_indicated_vat: "Prix indiqués en TTC",
                issue_drafts_labels: {
                    yes: "Oui",
                    no: "Non"
                },
                taxes_included_labels: {
                    yes: "Oui",
                    no: "Non"
                }
            },
            all_activity: "Tous",
            deleted: "Supprimé",
            not_found: {
                heading: "Éditez ici les factures pour vendre vos services et produits",
                sub_heading: "Vous pourrez suivre le statut de chaque facture, de l'émission jusqu'à l'encaissement",
            },
            reference_date: "Date de référence :",
            tabs: {
                all: "Tous",
                paid: "Payé",
                not_paid: "Non payé",
                overdue: "En retard",
                cancelled: "Annulé",
                draft: "Brouillon",
            },
            columns: {
                reference_date_labels: {
                    date: "Date",
                    issue_date: "Date d'émission",
                    paid_date: "Date de paiement",
                    execution_date: "Date de vente",
                    refund_date: "Date de remboursement",
                },
                date: "Date",
                reference: "Référence",
                client: "Client",
                subject: "Objet",
                total_exc_taxes: "Total HT",
                total_inc_taxes: "Total TTC",
                status: "Statut",
                status_tag: {
                    paid: "Payé",
                    cancelled: "Annulé",
                    overdue: "En retard",
                    draft: "Brouillon",
                    not_paid: "Non payé",
                    null: ""
                },
            },
            search_client_or_invoices: "Rechercher un numéro de facture ou client",
            delete_popup: {
                heading: "Êtes-vous sûr ?",
                description: "Les factures sélectionnées seront supprimées ou annulées par un avoir",
                no_btn: "Non",
                yes_btn: "Oui",
            },
            toolbar: {
                activity: "Activité",
                start_date: "Date de début",
                end_date: "Date de fin",
                date: "Date",
                clear: "Effacer",
                reference_date_labels: {
                    date: "Date",
                    issue_date: "Date d'émission",
                    paid_date: "Date de paiement",
                    execution_date: "Date de vente",
                    refund_date: "Date de remboursement",
                },
            },
            date_filters: {
                today: "Aujourd'hui",
                yesterday: "Hier",
                this_week: "Cette semaine",
                last_week: "La semaine dernière",
                this_month: "Ce mois",
                last_month: "Le mois dernier",
                last_30_days: "Les 30 derniers jours",
                last_90_days: "90 derniers jours",
                last_12_months: "12 derniers mois",
                this_year: "Cette année",
                last_year: "L'année dernière",
                absolute: "Absolue",
                relative: "Relative",
                between: "Entre",
                is_after: "Est après",
                is_before: "Est avant",
                all_period: "Période entière",
            },
            selected: "Sélectionné(s)",
            delete: "Supprimer",
            summary: {
                all: "Tous",
                paid: "Payé",
                not_paid: "Non payé",
                overdue: "En retard",
                cancelled: "Annulé",
                draft: "Brouillon",
                invoices: "Factures",
            },
        },
        view: {
            deleted: "Supprimé",
            duplicated: "Dupliqué",
            created_delivery_form: "Formulaire de livraison créé",
            issued: "Émis",
            view_invoice: "Voir la facture",
            edit: "Modifier",
            more_actions: "Plus d'actions",
            edit_menu: {
                modify_invoice: "Modifier la facture",
                duplicate_invoice: "Dupliquer la facture",
                create_delivery_form: "Créer un bon de livraison",
            },
            delete: "Supprimer",
            void: "Annuler",
            pay: "Payer",
            finalize: "Finaliser",
            finalize_popup: {
                heading: "Finaliser la facture ?",
                description: "Une fois émise la facture ne pourra plus être modifiée",
                cancel_btn: "Cancel",
                finalize_btn: "Finaliser",
            },
            status_tag: {
                paid: "Payé",
                cancelled: "Annulé",
                overdue: "En retard",
                draft: "Brouillon",
                not_paid: "Non payé",
                null: ""
            },
            delete_popup: {
                heading: "Supprimer la facture ?",
                void_heading: "Annuler la facture ?",
                description: "Cette opération est irréversible !",
                void_description: "Un avoir doit être émis pour annuler cette facture",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
                create_credit_note_btn: "Émettre un avoir",
            },
            summary: {
                client: "Clients",
                total_excl_vat: "Total HT",
                total_incl_vat: "Total TTC",
                date: "Date",
                overview: "Récapitulatif",
                delay_expiration: "Délai de validité",
                days: "Jours",
                urssaf_taxes: "Cotisations URSSAF",
                status_of_payments: "Paiements",
                sales_overview: "Récapitulatif",
                warning_overdue_days: "Attention ! Le paiement est en retard de {{days}} jours !",
                warning_overdue_day: "Attention ! Le paiement est en retard de {{day}} jour !",
                delay_before_due_date: "Échéance",
                overdue: "En retard",
            },
            toolbar: {
                view_detail_options: {
                    events: "Événements",
                    payments: "Paiements",
                    credit_notes: "Avoirs",
                    attachments: "Pièces jointes",
                },
                download: "Télécharger",
                print: "Imprimer",
                send: "Envoyer",
                hide_notes: "Masquer les notes",
                notes: "Remarques",
                show_more: "Voir plus",
                send_modal: {
                    error: {
                        email: "L'email est requis",
                        title: "L'objet est requis",
                        body: "Le corps est requis",
                        invalid_emails: "Adresses e-mail invalides",
                    },
                    sent: "Envoyé",
                    subject: "Objet",
                    email: "Adresse e-mail",
                    multi_email_info: "Vous pouvez envoyer à plusieurs destinataires en séparant les e-mails par une virgule",
                    send: "Envoyer",
                    cancel_btn: "Annuler",
                    send_btn: "Envoyer",
                },
            },
            payment_modal: {
                transaction_link_desc: "Cette recette est liée à une transaction bancaire et certains éléments ne sont pas modifiables",
                error: {
                    amount: "Le montant est requis",
                    date: "La date est requise",
                    method: "La méthode est requise",
                    invoice: "Une facture est requise",
                    amount_min_max: "Doit être entre {{min}} et {{max}}",
                },
                create_payment_success: "Paiement enregistré",
                update_payment_success: "Paiement mis à jour",
                invoice_to_xxx: "Facture à {{name}}",
                payment: "Paiement",
                loading: "Chargement...",
                no_invoice: "Aucune facture trouvée",
                invoice_to_paid: "Facture à payer",
                date: "Date",
                amount: "Montant",
                label: "Libellé",
                payment_mode: "Mode de paiement",
                notes: "Remarques",
                note_by_cheque: " N° chèque, client,...",
                cancel_btn: "Annuler",
                view_invoice_btn: "Voir la facture",
                view_credit_note_btn: "Afficher l'avoir",
                save_btn: "Enregistrer",
                payment_methods: {
                    bank_transfer: "Virement",
                    cash: "Espèces",
                    check: "Chèque",
                    cesu: "Cesu",
                    direct_debit: "Prélèvement",
                    credit_card: "Carte bancaire",
                    other: "Autre",
                    paypal: "PayPal",
                    gift_card: "Carte cadeau",
                    null: ""
                },
            },
            more_details_panel: {
                events: {
                    history: "Historique",
                    timeline_of_events: "Chronologie des événements",
                },
                payments: {
                    delete_payment_success: "Paiement supprimé",
                    certificate_name: "attestation_paiements_{{name}}.pdf",
                    downloaded: "Téléchargé",
                    download_statements: "Attestation de paiement",
                    payments: "Paiements",
                    payment_list: "Liste des paiements imputés à cette facture",
                    payment_methods: {
                        bank_transfer: "Virement",
                        cash: "Espèces",
                        check: "Chèque",
                        cesu: "Cesu",
                        direct_debit: "Prélèvement",
                        credit_card: "Carte bancaire",
                        other: "Autre",
                        paypal: "PayPal",
                        gift_card: "Carte cadeau",
                        null: ""
                    },
                    delete_popup: {
                        heading: "Supprimer le paiement ?",
                        description: "Cette opération est irréversible !",
                        cancel_btn: "Annuler",
                        delete_btn: "Supprimer",
                    }
                },
                credit_notes: {
                    credit_notes: "Avoirs",
                    list_credit_notes_invoice_attached: "Liste des avoirs joints à cette facture",
                },
                attachments: {
                    attachments: "Pièces jointes",
                    select_files: "Sélectionnez les fichiers",
                }
            },
            pdf: {
                loading_pdf: "Chargement du PDF",
                of: "de",
            },
            edit_popup: {
                error: {
                    activity: "L'activité est requise",
                    title: "Le titre est requis",
                    quantity: "La quantité est requise",
                    country: "Le pays est requis",
                    price: "Le prix est requis",
                    payment_gateway: "Passerelle de paiement requise",
                },
                modify_invoice: "Modifier la facture",
                updated: "Mis à jour",
                sales_type: "Type de vente",
                country_of_origin: "Pays de départ des articles",
                choose_a_country: "Choisissez un pays",
                tracking_number: "Numéro de suivi colis",
                purchase_price: "Prix d'achat",
                activity: "Activités",
                tracking_numbers: "Numéros de suivi colis",
                shipping_companies: "Transporteurs",
                cancel_btn: "Annuler",
                save_btn: "Enregistrer",
                accept_online_payment: "Activer le paiement en ligne",
                select_payment_gateways: "Sélectionnez les services de paiement",
                same_type_payment_already_selected: "Déjà le même type sélectionné",
                connect_payment_gateway: "Connectez un service pour accepter les paiements en ligne",
                connect_btn: "Connecter",
            },
        },
        edit: {
            alert_info: "Une fois émis, un document comptable ne peut pas être modifié ou supprimé afin de respecter la numérotation chronologique des documents. Vous pouvez cependant modifier certaines informations pour ajuster le calcul de vos cotisations et taxes. Pour rectifier cette facture, vous devez l'annuler en adressant à votre client un avoir et émettre une nouvelle facture.",
            status_tag: {
                paid: "Payé",
                cancelled: "Annulé",
                overdue: "En retard",
                draft: "Brouillon",
                not_paid: "Non payé",
                null: ""
            },
            new_invoice: "Nouveau facture",
            error: {
                date: "La date est requise",
                invalid_date: "La date n'est pas valide",
                language: "La langue est requise",
                currency: "La devise est requise",
                activity: "L'activité est requise",
                template: "Le modèle est requis",
                last_name: "Le nom est requis",
                country: "Le pays est requis",
                company_name: "Nom de l'entreprise requis",
                address: "L'adresse est requise",
                city: "La ville est requise",
                client: "Le client est requis",
                zip: "Le code postal est requis",
                title: "Le titre est requis",
                quantity: "La quantité est requise",
                price: "Le prix est requis",
                price_min: "Prix minimum autorisé {{amount}}",
                tree: "Au minimum un article est requis",
                payment_gateway: "Passerelle de paiement requise",
            },
            restore_version: "Vous avez des éléments non sauvegardés",
            draft_actions: {
                apply: "Restaurer",
                discard: "Ignorer",
            },
            created: "Facture créé",
            updated: "Facture mis à jour",
            saved: "Enregistré",
            cancel_btn: "Annuler",
            save_btn: "Enregistrer",
            confirm_rule_popup: {
                heading: "Mise à jour de la TVA",
                description: "Le taux de TVA doit être changé en conformité avec les informations présentes sur la facture, voulez-vous le modifier ?",
                yes_btn: "Oui",
                no_btn: "Non",
            },
            vat_section: {
                price_labels: {
                    vat_incl: "TTC",
                    vat_excl: "HT",
                },
                vat_labels: {
                    no: "Non",
                    yes: "Oui",
                },
                vat_exempt: "Exonération de TVA",
                price_format: "Format de prix",
                exemption_text: "Message d'exonération",
                exemption_placeholder: "TVA non applicable...",
            },
            filter_section: {
                language_input_label: "Langue",
                currency_input_label: "Devise",
                activity_input_label: "Activité",
                select_template_input_label: "Modèle",
                subject_input_label: "Objet",
                invoice_title: "Intitulé de la facture...",
                expiration_date_input_label: "Date d'expiration",
                order_date_input_label: "Date de vente / réalisation",
            },
            pdf_section: {
                loading_pdf: "Chargement du PDF",
                of: "de",
            },
            client_section: {
                client: "Client",
                select_client_input_label: "Sélectionnez un client",
                no_client_found: "Aucun client trouvé",
                use_another_shipping: "Adresse de livraison différente",
                another_shipping: {
                    company_input_label: "Entreprise",
                    vat_number_input_label: "TVA intracommunautaire",
                    registration_number_input_label: "SIREN",
                    last_name_input_label: "Nom",
                    first_name_input_label: "Prénom",
                    address_input_label: "Adresse",
                    address_supplementary_input_label: "Complément d'adresse",
                    zip_input_label: "Code postal",
                    city_input_label: "Ville",
                    country_input_label: "Pays",
                    client_type_labels: {
                        individual: "Particulier",
                        business: "Entreprise",
                    },
                }
            },
            article_section: {
                articles: "Articles",
                no_article_found: "Aucun article trouvé",
                loading: "Chargement...",
                choose_article: "Sélectionnez un article",
                search_articles: "Rechercher un article",
                edit_line: "Modifier la ligne",
                add_new_line: "Ajouter un article",
                edit_popup: {
                    ref: "Réf",
                    title: "Titre",
                    description: "Description",
                    insert_tag: "Insérez une balise :",
                    tags: {
                        start_date: "Date de début",
                        end_date: "Date de fin",
                        start_month: "Mois de début",
                        end_month: "Mois de fin",
                        start_year: "Année de début",
                        end_year: "Fin d'année",
                    },
                    amount: "Montant",
                    vat: "TVA",
                    no_results_found: "Aucun résultat trouvé.",
                    discount: "Remise",
                    qte: "QTE",
                    unit: "Unité",
                    sales_type: "Type de vente",
                    country_of_origin: "Pays de départ des articles",
                    choose_a_country: "Choisissez un pays",
                    tracking_number: "Numéro de suivi colis",
                    second_hand_good: "Article d'occasion",
                    purchase_price: "Prix d'achat",
                    save_article: "Enregistrer l'article",
                    save_btn: "Enregistrer",
                    cancel_btn: "Annuler",
                    unit_labels: {
                        piece: "Pièce",
                        hour: "Heure",
                        day: "Jour",
                        meter: "Mètre",
                        kilometer: "Kilomètre",
                        gram: "Gramme",
                        kilogram: "Kilogramme",
                        square_meter: "Mètre carré",
                    }
                }
            },
            discount_section: {
                discount_shipping: "Réduction / Frais de port",
                discount: "Remise",
                shipping_cost: "Frais de port",
                vat: "TVA",
                no_results_found: "Aucun résultat trouvé.",
            },
            payment_section: {
                payment: "Paiement",
                payment_method: "Modes de paiement",
                accept_online_payment: "Activer le paiement en ligne",
                select_payment_gateways: "Sélectionnez les services de paiement",
                same_type_payment_already_selected: "Déjà le même type sélectionné",
                connect_payment_gateway: "Connectez un service pour accepter les paiements en ligne",
                connect_btn: "Connecter",
                payment_methods: {
                    bank_transfer: "Virement",
                    cash: "Espèces",
                    check: "Chèque",
                    cesu: "Cesu",
                    direct_debit: "Prélèvement",
                    credit_card: "Carte bancaire",
                    other: "Autre",
                    paypal: "PayPal",
                    gift_card: "Carte cadeau",
                    null: ""
                },
                payment_delay: "Délai de paiement",
                penalty_rate: "Intérêts retard",
                rebate: "Escompte",
                payment_terms: "Ajouter les modalités de paiement",
                payment_delay_labels: {
                    upon_invoice: "A réception",
                    upon_order: "A la commande",
                    upon_15_net: "15 jours nets",
                    upon_30_net: "30 jours nets",
                    upon_45_net: "45 jours nets",
                    upon_60_net: "60 jours nets",
                    upon_30_end: "30 jours nets fin de mois",
                    upon_45_end: "45 jours nets fin de mois",
                    upon_30_end_offset_10: "30 jours nets fin de mois, le 10",
                    upon_30_end_offset_20: "30 jours nets fin de mois, le 20",
                    none: "Aucun",
                }
            },
            option_section: {
                options: "Options",
                comments: "Commentaires",
                tracking_numbers: "Numéros de suivi colis",
                shipping_companies: "Transporteurs",
            },
            attachment_section: {
                attachments: "Pièces jointes",
                latest_added_attachments: "Dernières pièces jointes ajoutées",
            }
        },
    },
    invoice: {
        history: {
            email: "Facture envoyée à {{email}}",
            emailSent: "Facture envoyée à {{email}}",
            paymentAdded: "Paiement de {{total}} {{currency}} ajouté",
            paymentRemoved: "Paiement de {{total}} {{currency}} supprimé",
            issued: "Facture émise",
            backIssued: "Facture non soldée",
            cancelled: "Facture annulée",
            asset: "La facture a fait l'object d'un avoir",
            created: "Facture créée",
            paid: "Facture soldée",
            issuedAsset: "Avoir créé",
            paymentGift: "Carte cadeau de {{total}} {{currency}} utilisée",
            emailRead: "Facture consultée"
        },
    },
    recurring_invoices: {
        nav_title: "Récurrentes",
        list: {
            heading: "Factures récurrentes",
            new_invoice_btn: "Nouvelle facture récurrente",
            deleted: "Facture récurrente supprimée",
            delete_fail: "Impossible de supprimer",
            disabled: "Désactivé",
            enabled: "Activé",
            not_found: {
                heading: "Éditez ici les factures récurrentes pour vos ventes régulières",
                sub_heading: "Les factures seront automatiquement émises avec les information indiquées, à la date programmée",
            },
            columns: {
                client: "Client",
                frequency_title: "Fréquence",
                duration_title: "Durée",
                next_inv: "Prochaine facture",
                amount_title: "Total TTC",
                status: "Statut",
            },
            delete_popup: {
                heading: "Attention",
                description: "Voulez-vous vraiment supprimer cette facture récurrente ?",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
            },
            edit_menu: {
                disable: "Désactiver",
                enable: "Activer",
                modify: "Modifier",
                delete: "Supprimer",
                edit: "Modifier",
            },
            frequency: {
                weekly: "Hebdomadaire",
                monthly: "Mensuel",
                quarterly: "Trimestriel",
                yearly: "Annuel",
                custom: "Personnalisé",
            },
            duration: {
                not_limited: "Non limité",
                stop_after_date: "Arrêt après une date",
                until: "Jusqu'au"
            },
            status_labels: {
                enabled: "Actif",
                disabled: "Inactif"
            },
        },
        edit: {
            status_tag: {
                paid: "Payé",
                cancelled: "Annulé",
                overdue: "En retard",
                draft: "Brouillon",
                not_paid: "Non payé",
                null: ""
            },
            new_invoice: "Nouveau facture",
            error: {
                date: "La date est requise",
                invalid_date: "La date n'est pas valide",
                language: "La langue est requise",
                currency: "La devise est requise",
                activity: "L'activité est requise",
                template: "Le modèle est requis",
                last_name: "Le nom est requis",
                country: "Le pays est requis",
                company_name: "Nom de l'entreprise requis",
                address: "L'adresse est requise",
                city: "La ville est requise",
                client: "Le client est requis",
                zip: "Le code postal est requis",
                title: "Le titre est requis",
                quantity: "La quantité est requise",
                price: "Le prix est requis",
                price_min: "Prix minimum autorisé {{amount}}",
                tree: "Au minimum un article est requis",
                min_start_date: "La date de fin doit être postérieure à la date de début",
                occurrence_type: "Le type est requis",
                occurrence_value: "La valeur est requise",
                payment_gateway: "Passerelle de paiement requise",
            },
            restore_version: "Vous avez des éléments non sauvegardés",
            draft_actions: {
                apply: "Restaurer",
                discard: "Ignorer",
            },
            created: "Facture créé",
            updated: "Facture mis à jour",
            saved: "Enregistré",
            cancel_btn: "Annuler",
            save_btn: "Enregistrer",
            confirm_rule_popup: {
                heading: "Mise à jour de la TVA",
                description: "Le taux de TVA doit être changé en conformité avec les informations présentes sur la facture, voulez-vous le modifier ?",
                yes_btn: "Oui",
                no_btn: "Non",
            },
            vat_section: {
                price_labels: {
                    vat_incl: "TTC",
                    vat_excl: "HT",
                },
                vat_labels: {
                    no: "Non",
                    yes: "Oui",
                },
                vat_exempt: "Exonération de TVA",
                price_format: "Format de prix",
                exemption_text: "Message d'exonération",
                exemption_placeholder: "TVA non applicable...",
            },
            setting_section: {
                frequency: {
                    weekly: "Hebdomadaire",
                    monthly: "Mensuel",
                    quarterly: "Trimestriel",
                    yearly: "Annuel",
                    custom: "Personnalisé",
                },
                duration: {
                    not_limited: "Non limité",
                    stop_after_date: "Arrêt après une date",
                    until: "Jusqu'au"
                },
                dwmy: {
                    day: "Jours",
                    week: "Semaines",
                    month: "Mois",
                    year: "Ans",
                },
                settings: "Paramètres",
                date_first_invoice: "Date de la 1ère facture",
                frequency_title: "Fréquence",
                duration_title: "Durée",
                send_invoice: "Envoyer les factures",
            },
            filter_section: {
                language_input_label: "Langue",
                currency_input_label: "Devise",
                activity_input_label: "Activité",
                select_template_input_label: "Modèle",
                subject_input_label: "Objet",
                invoice_title: "Intitulé de la facture...",
                expiration_date_input_label: "Date d'expiration",
                order_date_input_label: "Date de vente / réalisation",
            },
            pdf_section: {
                loading_pdf: "Chargement du PDF",
                of: "de",
            },
            client_section: {
                client: "Client",
                select_client_input_label: "Sélectionnez un client",
                no_client_found: "Aucun client trouvé",
                use_another_shipping: "Adresse de livraison différente",
                another_shipping: {
                    company_input_label: "Entreprise",
                    vat_number_input_label: "TVA intracommunautaire",
                    registration_number_input_label: "SIREN",
                    last_name_input_label: "Nom",
                    first_name_input_label: "Prénom",
                    address_input_label: "Adresse",
                    address_supplementary_input_label: "Complément d'adresse",
                    zip_input_label: "Code postal",
                    city_input_label: "Ville",
                    country_input_label: "Pays",
                    client_type_labels: {
                        individual: "Particulier",
                        business: "Entreprise",
                    },
                }
            },
            article_section: {
                articles: "Articles",
                no_article_found: "Aucun article trouvé",
                loading: "Chargement...",
                choose_article: "Sélectionnez un article",
                search_articles: "Rechercher un article",
                edit_line: "Modifier la ligne",
                add_new_line: "Ajouter un article",
                edit_popup: {
                    ref: "Réf",
                    title: "Titre",
                    description: "Description",
                    insert_tag: "Insérez une balise :",
                    tags: {
                        start_date: "Date de début",
                        end_date: "Date de fin",
                        start_month: "Mois de début",
                        end_month: "Mois de fin",
                        start_year: "Année de début",
                        end_year: "Fin d'année",
                    },
                    amount: "Montant",
                    vat: "TVA",
                    no_results_found: "Aucun résultat trouvé.",
                    discount: "Remise",
                    qte: "QTE",
                    unit: "Unité",
                    sales_type: "Type de vente",
                    country_of_origin: "Pays de départ des articles",
                    choose_a_country: "Choisissez un pays",
                    tracking_number: "Numéro de suivi colis",
                    second_hand_good: "Article d'occasion",
                    purchase_price: "Prix d'achat",
                    save_article: "Enregistrer l'article",
                    save_btn: "Enregistrer",
                    cancel_btn: "Annuler",
                    unit_labels: {
                        piece: "Pièce",
                        hour: "Heure",
                        day: "Jour",
                        meter: "Mètre",
                        kilometer: "Kilomètre",
                        gram: "Gramme",
                        kilogram: "Kilogramme",
                        square_meter: "Mètre carré",
                    }
                }
            },
            discount_section: {
                discount_shipping: "Réduction / Frais de port",
                discount: "Remise",
                shipping_cost: "Frais de port",
                vat: "TVA",
                no_results_found: "Aucun résultat trouvé.",
            },
            payment_section: {
                payment: "Paiement",
                payment_method: "Modes de paiement",
                accept_online_payment: "Activer le paiement en ligne",
                select_payment_gateways: "Sélectionnez les services de paiement",
                same_type_payment_already_selected: "Déjà le même type sélectionné",
                connect_payment_gateway: "Connectez un service pour accepter les paiements en ligne",
                connect_btn: "Connecter",
                payment_methods: {
                    bank_transfer: "Virement",
                    cash: "Espèces",
                    check: "Chèque",
                    cesu: "Cesu",
                    direct_debit: "Prélèvement",
                    credit_card: "Carte bancaire",
                    other: "Autre",
                    paypal: "PayPal",
                    gift_card: "Carte cadeau",
                    null: ""
                },
                payment_delay: "Délai de paiement",
                penalty_rate: "Intérêts retard",
                rebate: "Escompte",
                payment_terms: "Ajouter les modalités de paiement",
                payment_delay_labels: {
                    upon_invoice: "A réception",
                    upon_order: "A la commande",
                    upon_15_net: "15 jours nets",
                    upon_30_net: "30 jours nets",
                    upon_45_net: "45 jours nets",
                    upon_60_net: "60 jours nets",
                    upon_30_end: "30 jours nets fin de mois",
                    upon_45_end: "45 jours nets fin de mois",
                    upon_30_end_offset_10: "30 jours nets fin de mois, le 10",
                    upon_30_end_offset_20: "30 jours nets fin de mois, le 20",
                    none: "Aucun",
                }
            },
            option_section: {
                options: "Options",
                comments: "Commentaires",
                tracking_numbers: "Numéros de suivi colis",
                shipping_companies: "Transporteurs",
            },
            attachment_section: {
                attachments: "Pièces jointes",
                latest_added_attachments: "Dernières pièces jointes ajoutées",
            }
        }
    },
    credit_notes: {
        nav_title: "Avoirs",
        list: {
            heading: "Avoirs",
            export_popup: {
                error: {
                    invalid_email: "L'email est invalide",
                    email: "L'e-mail est requis",
                },
                export: "Exporter",
                export_msg: "L'export sera envoyé dans votre boîte e-mail dans quelques instants",
                export_as_pdf: "Format PDF",
                export_as_csv: "Format CSV",
                heading: "Exporter des avoirs",
                sub_heading: "Ajustez les critères pour télécharger vos avoirs",
                export_x_invoice: "Exporter {{count}} avoirs",
                date_filters: {
                    today: "Aujourd'hui",
                    yesterday: "Hier",
                    this_week: "Cette semaine",
                    last_week: "La semaine dernière",
                    this_month: "Ce mois",
                    last_month: "Le mois dernier",
                    last_30_days: "Les 30 derniers jours",
                    last_90_days: "90 derniers jours",
                    last_12_months: "12 derniers mois",
                    this_year: "Cette année",
                    last_year: "L'année dernière",
                },
                reference_date_options: {
                    date_of_issue: "Date d'émission",
                    date_of_sale: "Date de vente",
                    payment_date: "Date de paiement",
                },
                billing_period: "Période de facturation",
                more_filters: "Plus de filtres",
                less_filters: "Moins de filtres",
                from_input_label: "Du",
                to_input_label: "Au",
                loading: "Chargement...",
                not_found: "Aucun pays trouvé",
                country_input_label: "Pays",
                no_activity_found: "Aucune activité trouvée",
                activity_input_label: "Activité",
                filter_by: "Filtrer par",
                recipient: "Destinataire",
                email_input_label: "Email",
                cancel_btn: "Annuler",
                export_limit_alert: "Vous ne pouvez pas exporter plus de {{limit}} éléments à la fois, veuillez ajuster les filtres et la période",
                pdf_merged_label: "Regrouper les factures dans un seul fichier",
            },
            selected: "Sélectionné(s)",
            delete: "Supprimer",
            all_activity: "Tous",
            deleted: "Supprimé",
            not_found: {
                heading: "Visualisez ici les avoirs de vos factures",
                sub_heading: "Lorsque vous faites des remboursements et annulez des factures, les avoirs sont automatiquement créés",
            },
            reference_date: "Date de référence :",
            issue_date: "Date d'émission",
            refund_date: "Date de remboursement",
            search: "Chercher",
            delete_popup: {
                heading: "Supprimer les avoirs",
                description: "Voulez-vous supprimer ces avoirs ?",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
            },
            tabs: {
                all: "Tous",
                issued: "Émis",
                draft: "Brouillon",
            },
            columns: {
                reference_date_labels: {
                    date: "Date",
                    issue_date: "Date d'émission",
                    paid_date: "Date de paiement",
                    execution_date: "Date de vente",
                    refund_date: "Date de remboursement",
                },
                date: "Date",
                reference: "Référence",
                client: "Client",
                subject: "Objet",
                total_exc_taxes: "Total HT",
                total_inc_taxes: "Total TTC",
                status: "Statut",
                status_labels: {
                    issued: "Émis",
                    draft: "Brouillon",
                    null: ""
                }
            },
            toolbar: {
                activity: "Activité",
                start_date: "Date de début",
                end_date: "Date de fin",
                date: "Date",
                clear: "Effacer",
                reference_date_labels: {
                    date: "Date",
                    issue_date: "Date d'émission",
                    paid_date: "Date de paiement",
                    execution_date: "Date de vente",
                    refund_date: "Date de remboursement",
                },
            },
            date_filters: {
                today: "Aujourd'hui",
                yesterday: "Hier",
                this_week: "Cette semaine",
                last_week: "La semaine dernière",
                this_month: "Ce mois",
                last_month: "Le mois dernier",
                last_30_days: "Les 30 derniers jours",
                last_90_days: "90 derniers jours",
                last_12_months: "12 derniers mois",
                this_year: "Cette année",
                last_year: "L'année dernière",
                absolute: "Absolue",
                relative: "Relative",
                between: "Entre",
                is_after: "Est après",
                is_before: "Est avant",
                all_period: "Période entière",
            },
        },
        view: {
            deleted: "Supprimé",
            view_invoice: "Voir la facture",
            issued: "Émis",
            edit: "Modifier",
            delete: "Supprimer",
            void: "Annuler",
            pay: "Payer",
            finalize_invoice_first: "Finalisez d'abord la facture",
            finalize: "Finaliser",
            finalize_popup: {
                heading: "Finaliser l'avoir ?",
                description: "Une fois émis l'avoir ne pourra plus être modifié",
                cancel_btn: "Cancel",
                finalize_btn: "Finaliser",
            },
            status_tag: {
                paid: "Payé",
                cancelled: "Annulé",
                overdue: "En retard",
                draft: "Brouillon",
                not_paid: "Non payé",
                null: ""
            },
            delete_popup: {
                heading: "Supprimer la facture ?",
                void_heading: "Annuler la facture ?",
                description: "Cette opération est irréversible !",
                void_description: "Un avoir doit être émis pour annuler cette facture",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
                create_credit_note_btn: "Émettre un avoir",
            },
            summary: {
                client: "Clients",
                total_excl_vat: "Total HT",
                total_incl_vat: "Total TTC",
                date: "Date",
                overview: "Récapitulatif",
                delay_expiration: "Délai de validité",
                days: "Jours",
                urssaf_taxes: "Cotisations URSSAF",
                status_of_payments: "Paiements",
                sales_overview: "Récapitulatif",
                warning_overdue_days: "Attention ! Le paiement est en retard de {{days}} jours !",
                warning_overdue_day: "Attention ! Le paiement est en retard de {{day}} jour !",
                delay_before_due_date: "Échéance",
                overdue: "En retard",
            },
            toolbar: {
                view_detail_options: {
                    events: "Événements",
                    payments: "Remboursements",
                    credit_notes: "Avoirs",
                    attachments: "Pièces jointes",
                },
                download: "Télécharger",
                print: "Imprimer",
                send: "Envoyer",
                hide_notes: "Masquer les notes",
                notes: "Remarques",
                show_more: "Voir plus",
                send_modal: {
                    error: {
                        email: "L'email est requis",
                        title: "L'objet est requis",
                        body: "Le corps est requis",
                        invalid_emails: "Adresses e-mail invalides",
                    },
                    sent: "Envoyé",
                    subject: "Objet",
                    email: "Adresse e-mail",
                    multi_email_info: "Vous pouvez envoyer à plusieurs destinataires en séparant les e-mails par une virgule",
                    send: "Envoyer",
                    cancel_btn: "Annuler",
                    send_btn: "Envoyer",
                },
            },
            more_details_panel: {
                events: {
                    history: "Historique",
                    timeline_of_events: "Chronologie des événements",
                },
                payments: {
                    delete_payment_success: "Paiement supprimé",
                    certificate_name: "attestation_paiements_{{name}}.pdf",
                    downloaded: "Téléchargé",
                    download_statements: "Attestation de paiement",
                    payments: "Paiements",
                    payment_list: "Liste des paiements imputés à cette facture",
                    refunds: "Remboursements",
                    list_refunds_to_credit_note: "Liste des remboursements imputés à cet avoir",
                    payment_methods: {
                        bank_transfer: "Virement",
                        cash: "Espèces",
                        check: "Chèque",
                        cesu: "Cesu",
                        direct_debit: "Prélèvement",
                        credit_card: "Carte bancaire",
                        other: "Autre",
                        paypal: "PayPal",
                        gift_card: "Carte cadeau",
                        null: ""
                    },
                    delete_popup: {
                        heading: "Supprimer le paiement ?",
                        description: "Cette opération est irréversible !",
                        cancel_btn: "Annuler",
                        delete_btn: "Supprimer",
                    }
                },
            },
            pdf: {
                loading_pdf: "Chargement du PDF",
                of: "de",
            },
            payment_modal: {
                transaction_link_desc: "Cette recette est liée à une transaction bancaire et certains éléments ne sont pas modifiables",
                error: {
                    amount: "Le montant est requis",
                    date: "La date est requise",
                    method: "La méthode est requise",
                    invoice: "Une facture est requise",
                    amount_min_max: "Doit être entre {{min}} et {{max}}",
                },
                create_payment_success: "Paiement enregistré",
                update_payment_success: "Paiement mis à jour",
                invoice_to_xxx: "Facture à {{name}}",
                payment: "Paiement",
                loading: "Chargement...",
                no_invoice: "Aucune facture trouvée",
                invoice_to_paid: "Facture à payer",
                date: "Date",
                amount: "Montant",
                label: "Libellé",
                payment_mode: "Mode de paiement",
                notes: "Remarques",
                note_by_cheque: " N° chèque, client,...",
                cancel_btn: "Annuler",
                view_invoice_btn: "Voir la facture",
                view_credit_note_btn: "Afficher l'avoir",
                save_btn: "Enregistrer",
                payment_methods: {
                    bank_transfer: "Virement",
                    cash: "Espèces",
                    check: "Chèque",
                    cesu: "Cesu",
                    direct_debit: "Prélèvement",
                    credit_card: "Carte bancaire",
                    other: "Autre",
                    paypal: "PayPal",
                    gift_card: "Carte cadeau",
                    null: ""
                },
            },
            edit_popup: {
                error: {
                    activity: "L'activité est requise",
                    title: "Le titre est requis",
                    quantity: "La quantité est requise",
                    country: "Le pays est requis",
                    price: "Le prix est requis",
                    payment_gateway: "Passerelle de paiement requise",
                },
                modify_invoice: "Modifier la facture",
                updated: "Mis à jour",
                sales_type: "Type de vente",
                country_of_origin: "Pays de départ des articles",
                choose_a_country: "Choisissez un pays",
                tracking_number: "Numéro de suivi colis",
                purchase_price: "Prix d'achat",
                activity: "Activités",
                tracking_numbers: "Numéros de suivi colis",
                shipping_companies: "Transporteurs",
                cancel_btn: "Annuler",
                save_btn: "Enregistrer",
                accept_online_payment: "Activer le paiement en ligne",
                select_payment_gateways: "Sélectionnez les services de paiement",
                same_type_payment_already_selected: "Déjà le même type sélectionné",
                connect_payment_gateway: "Connectez un service pour accepter les paiements en ligne",
                connect_btn: "Connecter",
            },
        },
        edit: {
            issued: "Émis",
            draft: "Brouillon",
            error: {
                date: "La date est requise",
                invalid_date: "La date n'est pas valide",
                language: "La langue est requise",
                currency: "La devise est requise",
                activity: "L'activité est requise",
                template: "Le modèle est requis",
                last_name: "Le nom est requis",
                country: "Le pays est requis",
                company_name: "Nom de l'entreprise requis",
                address: "L'adresse est requise",
                city: "La ville est requise",
                client: "Le client est requis",
                zip: "Le code postal est requis",
                title: "Le titre est requis",
                quantity: "La quantité est requise",
                price: "Le prix est requis",
                price_min: "Prix minimum autorisé {{amount}}",
                tree: "Au minimum un article est requis",
            },
            restore_version: "Vous avez des éléments non sauvegardés",
            draft_actions: {
                apply: "Restaurer",
                discard: "Ignorer",
            },
            created: "Avoir émis",
            updated: "Avoir mis à jour",
            saved: "Enregistré",
            cancel_btn: "Annuler",
            save_btn: "Enregistrer",
            confirm_rule_popup: {
                heading: "Mise à jour de la TVA",
                description: "Le taux de TVA doit être changé en conformité avec les informations présentes sur la facture, voulez-vous le modifier ?",
                yes_btn: "Oui",
                no_btn: "Non",
            },
            vat_section: {
                price_labels: {
                    vat_incl: "TTC",
                    vat_excl: "HT",
                },
                vat_labels: {
                    no: "Non",
                    yes: "Oui",
                },
                vat_exempt: "Exonération de TVA",
                price_format: "Format de prix",
                exemption_text: "Message d'exonération",
                exemption_placeholder: "TVA non applicable...",
            },
            filter_section: {
                language_input_label: "Langue",
                currency_input_label: "Devise",
                activity_input_label: "Activité",
                select_template_input_label: "Modèle",
                subject_input_label: "Objet",
                invoice_title: "Intitulé de la facture...",
                expiration_date_input_label: "Date d'expiration",
                order_date_input_label: "Date de vente / réalisation",
            },
            pdf_section: {
                loading_pdf: "Chargement du PDF",
                of: "de",
            },
            client_section: {
                client: "Client",
                select_client_input_label: "Sélectionnez un client",
                no_client_found: "Aucun client trouvé",
                use_another_shipping: "Adresse de livraison différente",
                another_shipping: {
                    company_input_label: "Entreprise",
                    vat_number_input_label: "TVA intracommunautaire",
                    registration_number_input_label: "SIREN",
                    last_name_input_label: "Nom",
                    first_name_input_label: "Prénom",
                    address_input_label: "Adresse",
                    address_supplementary_input_label: "Complément d'adresse",
                    zip_input_label: "Code postal",
                    city_input_label: "Ville",
                    country_input_label: "Pays",
                    client_type_labels: {
                        individual: "Particulier",
                        business: "Entreprise",
                    },
                }
            },
            article_section: {
                articles: "Articles",
                no_article_found: "Aucun article trouvé",
                loading: "Chargement...",
                choose_article: "Sélectionnez un article",
                search_articles: "Rechercher un article",
                edit_line: "Modifier la ligne",
                add_new_line: "Ajouter un article",
                edit_popup: {
                    ref: "Réf",
                    title: "Titre",
                    description: "Description",
                    insert_tag: "Insérez une balise :",
                    tags: {
                        start_date: "Date de début",
                        end_date: "Date de fin",
                        start_month: "Mois de début",
                        end_month: "Mois de fin",
                        start_year: "Année de début",
                        end_year: "Fin d'année",
                    },
                    amount: "Montant",
                    vat: "TVA",
                    no_results_found: "Aucun résultat trouvé.",
                    discount: "Remise",
                    qte: "QTE",
                    unit: "Unité",
                    sales_type: "Type de vente",
                    country_of_origin: "Pays de départ des articles",
                    choose_a_country: "Choisissez un pays",
                    tracking_number: "Numéro de suivi colis",
                    second_hand_good: "Article d'occasion",
                    purchase_price: "Prix d'achat",
                    save_article: "Enregistrer l'article",
                    save_btn: "Enregistrer",
                    cancel_btn: "Annuler",
                    unit_labels: {
                        piece: "Pièce",
                        hour: "Heure",
                        day: "Jour",
                        meter: "Mètre",
                        kilometer: "Kilomètre",
                        gram: "Gramme",
                        kilogram: "Kilogramme",
                        square_meter: "Mètre carré",
                    }
                }
            },
            discount_section: {
                discount_shipping: "Réduction / Frais de port",
                discount: "Remise",
                shipping_cost: "Frais de port",
                vat: "TVA",
                no_results_found: "Aucun résultat trouvé.",
            },
            option_section: {
                options: "Options",
                comments: "Commentaires",
                tracking_numbers: "Numéros de suivi colis",
                shipping_companies: "Transporteurs",
            },
        }
    },
    payments: {
        nav_title: "Recettes",
        list: {
            list_of_payments: "Recettes",
            success: "Succès",
            import: "Importer",
            external_payment: "Autre recette",
            import_popup: {
                success: "Succès",
                heading: "Importer des recettes",
                sub_heading: "Sélectionnez votre fichier contenant la liste des recettes à importer",
                validate_btn: "Valider",
                import_alert: "Pour en savoir plus sur les étapes à suivre, veuillez lire cet article :",
                cancel_btn: "Annuler",
                drop_csv_here: "Déposez votre fichier CSV ici",
            },
            export_popup: {
                error: {
                    invalid_email: "L'email est invalide",
                    email: "L'e-mail est requis",
                },
                export: "Exporter",
                export_msg: "L'export sera envoyé dans votre boîte e-mail dans quelques instants",
                export_as_pdf: "Format PDF",
                export_as_csv: "Format CSV",
                heading: "Livre des recettes",
                sub_heading: "Ajustez les critères pour télécharger vos recettes",
                export_x_payments: "Exporter {{count}} recettes",
                date_filters: {
                    today: "Aujourd'hui",
                    yesterday: "Hier",
                    this_week: "Cette semaine",
                    last_week: "La semaine dernière",
                    this_month: "Ce mois",
                    last_month: "Le mois dernier",
                    last_30_days: "Les 30 derniers jours",
                    last_90_days: "90 derniers jours",
                    last_12_months: "12 derniers mois",
                    this_year: "Cette année",
                    last_year: "L'année dernière",
                },
                billing_period: "Période de facturation",
                more_filters: "Plus de filtres",
                less_filters: "Moins de filtres",
                from_input_label: "Du",
                to_input_label: "Au",
                loading: "Chargement...",
                client_input_label: "Client",
                not_found: "Aucun pays trouvé",
                country_input_label: "Pays",
                no_activity_found: "Aucune activité trouvée",
                activity_input_label: "Activité",
                filter_by: "Filtrer par",
                recipient: "Destinataire",
                email_input_label: "Email",
                cancel_btn: "Annuler",
            },
            all_activity: "Tous",
            deleted: "Supprimé",
            delete: "Supprimer",
            selected: "Sélectionné(s)",
            not_found: {
                heading: "Visualisez ici les recettes de votre activité",
                sub_heading: "Lors de l'encaissement des factures, les recettes sont automatiquement ajoutées",
            },
            tabs: {
                all: "Tous",
                payments: "Paiements",
                refunds: "Remboursements",
            },
            columns: {
                payment_methods: {
                    bank_transfer: "Virement",
                    cash: "Espèces",
                    check: "Chèque",
                    cesu: "Cesu",
                    direct_debit: "Prélèvement",
                    credit_card: "Carte bancaire",
                    other: "Autre",
                    paypal: "PayPal",
                    gift_card: "Carte cadeau",
                    null: ""
                },
                date: "Date",
                reference: "Référence",
                client: "Client",
                label: "Libellé",
                total_exc_taxes: "Total HT",
                total_inc_taxes: "Total TTC",
                payment_method: "Modes de paiement",
            },
            search_invoice_number: "Rechercher une dépense...",
            delete_popup: {
                heading: "Supprimer les paiements ?",
                description: "Cette opération est irréversible !",
                cancel_btn: "Annuler",
                delete_btn: "Effacer",
            },
            toolbar: {
                activity: "Activité",
                start_date: "Date de début",
                end_date: "Date de fin",
                date: "Date",
                clear: "Effacer",
            },
            date_filters: {
                today: "Aujourd'hui",
                yesterday: "Hier",
                this_week: "Cette semaine",
                last_week: "La semaine dernière",
                this_month: "Ce mois",
                last_month: "Le mois dernier",
                last_30_days: "Les 30 derniers jours",
                last_90_days: "90 derniers jours",
                last_12_months: "12 derniers mois",
                this_year: "Cette année",
                last_year: "L'année dernière",
                absolute: "Absolue",
                relative: "Relative",
                between: "Entre",
                is_after: "Est après",
                is_before: "Est avant",
                all_period: "Période entière",
            },
            payment_modal: {
                transaction_link_desc: "Cette recette est liée à une transaction bancaire et certains éléments ne sont pas modifiables",
                error: {
                    amount: "Le montant est requis",
                    date: "La date est requise",
                    method: "La méthode est requise",
                    invoice: "Une facture est requise",
                    amount_min_max: "Doit être entre {{min}} et {{max}}",
                },
                create_payment_success: "Paiement enregistré",
                update_payment_success: "Paiement mis à jour",
                invoice_to_xxx: "Facture à {{name}}",
                payment: "Paiement",
                loading: "Chargement...",
                no_invoice: "Aucune facture trouvée",
                invoice_to_paid: "Facture à payer",
                date: "Date",
                amount: "Montant",
                label: "Libellé",
                payment_mode: "Mode de paiement",
                notes: "Remarques",
                note_by_cheque: " N° chèque, client,...",
                cancel_btn: "Annuler",
                view_invoice_btn: "Voir la facture",
                view_credit_note_btn: "Afficher l'avoir",
                save_btn: "Enregistrer",
                can_not_edit_voided: "Impossible de modifier le paiement d'une facture annulée",
                payment_methods: {
                    bank_transfer: "Virement",
                    cash: "Espèces",
                    check: "Chèque",
                    cesu: "Cesu",
                    direct_debit: "Prélèvement",
                    credit_card: "Carte bancaire",
                    other: "Autre",
                    paypal: "PayPal",
                    gift_card: "Carte cadeau",
                    null: ""
                },
            },
        },
        edit: {
            not_found: "Aucun paiement trouvé",
            created: "Paiement créé",
            updated: "Paiement mis à jour",
            deleted: "Supprimé",
            external_payment: "Autre recette",
            cancel_btn: "Annuler",
            delete_btn: "Supprimer",
            update_btn: "Enregistrer",
            create_btn: "Créer",
            info_alert: "Cette recette est liée à une transaction bancaire et certains éléments ne sont pas modifiables",
            delete_popup: {
                heading: "Supprimer le paiement",
                description: "Cette opération est irréversible !",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
            },
            rule_popup: {
                heading: "Mise à jour de la TVA",
                description: "Le taux de TVA doit être changé en conformité avec les informations présentes sur la facture, voulez-vous le modifier ?",
                no_btn: "Non",
                yes_btn: "Oui",
            },
            error: {
                date: "La date est requise",
                activity: "L'activité est requise",
                client: "Le client est requis",
                method: "Le mode de paiement est requis",
                currency: "La devise est requise",
                label: "Le libellé est requis",
                amount: "Le montant est requis",
                tax_rate: "Le taux de TVA est requis",
                type: "Le type est requis",
                origin_country_code: "Le pays est requis",
                total_must_be_xxx: "Le total du paiement doit être de {{total}}",
            },
            date_input_label: "Date",
            activity_input_label: "Activités",
            client_input_label: "Client",
            no_client_found: "Aucun client trouvé",
            currency_input_label: "Devise",
            payment_mode_input_label: "Mode de paiement",
            payment_methods: {
                bank_transfer: "Virement",
                cash: "Espèces",
                check: "Chèque",
                cesu: "Cesu",
                direct_debit: "Prélèvement",
                credit_card: "Carte bancaire",
                other: "Autre",
                paypal: "PayPal",
                gift_card: "Carte cadeau",
                null: ""
            },
            label_input_label: "Libellé",
            reference_input_label: "Référence",
            vat_exemption: "Exonération de TVA",
            title_input_label: "Titre",
            amount_input_label: "Montant",
            vat_input_label: "TVA",
            no_results_found: "Aucun résultat trouvé.",
            sales_type_input_label: "Type de vente",
            choose_a_country: "Choisissez un pays",
            country_origin_input_label: "Pays de départ des articles",
            add_another_line_btn: "Ajouter une ligne",
            total_amount: "Total TTC :",
            notes_input_label: "Remarques",
            documents: "Documents",
            vat_labels: {
                no: "Non",
                yes: "Oui",
            },
        }
    },
    quotes: {
        nav_title: "Devis",
        list: {
            list_quotes: "Liste des devis",
            quotes: "Devis",
            new_quote_btn: "Nouveau devis",
            deleted: "Devis supprimée",
            all_activity: "Tous",
            selected: "Sélectionné(s)",
            delete: "Supprimer",
            not_found: {
                heading: "Éditez ici les devis pour vendre vos services et produits",
                sub_heading: "Vous pouvez facturer directement les devis acceptés par vos clients",
            },
            summary: {
                all: "Tous",
                quotes: "Devis",
                pending: "En attente",
                accepted: "Accepté",
                declined: "Refusé",
                closed: "Clôturé",
                draft: "Brouillon",
            },
            tabs: {
                all: "Tous",
                pending: "En attente",
                accepted: "Accepté",
                declined: "Refusé",
                closed: "Clôturé",
                draft: "Brouillon",
            },
            search_client_or_invoices: "Rechercher un numéro de facture ou client",
            delete_popup: {
                heading: "Supprimer le devis",
                description: "Voulez-vous supprimer ce devis ?",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
            },
            columns: {
                date: "Date",
                reference: "Référence",
                client: "Client",
                subject: "Objet",
                total_exc_taxes: "Total HT",
                total_inc_taxes: "Total TTC",
                status: "Statut",
                status_tag: {
                    pending: "En attente",
                    accepted: "Accepté",
                    declined: "Refusé",
                    closed: "Clôturé",
                    draft: "Brouillon",
                    null: ""
                },
            },
            toolbar: {
                activity: "Activité",
                start_date: "Date de début",
                end_date: "Date de fin",
                date: "Date",
                clear: "Effacer",
            },
            date_filters: {
                today: "Aujourd'hui",
                yesterday: "Hier",
                this_week: "Cette semaine",
                last_week: "La semaine dernière",
                this_month: "Ce mois",
                last_month: "Le mois dernier",
                last_30_days: "Les 30 derniers jours",
                last_90_days: "90 derniers jours",
                last_12_months: "12 derniers mois",
                this_year: "Cette année",
                last_year: "L'année dernière",
                absolute: "Absolue",
                relative: "Relative",
                between: "Entre",
                is_after: "Est après",
                is_before: "Est avant",
                all_period: "Période entière",
            },
        },
        view: {
            view_quote: "Voir le devis",
            deleted: "Devis supprimée",
            set_quote: "Changer le statut",
            finalize_success: "Devis finalisé",
            accept_confirm: "Devis accepté",
            decline_confirm: "Devis refusé",
            accepted: "Accepté",
            declined: "Refusé",
            duplicate: "Dupliquer",
            status_tag: {
                pending: "En attente",
                accepted: "Accepté",
                declined: "Refusé",
                closed: "Clôturé",
                draft: "Brouillon",
                null: ""
            },
            note: {
                note_added: "Note ajoutée",
                error: {
                    content_require: "Contenu requis",
                },
            },
            down_payment: "Facture d'acompte",
            down_payment_description: "Indiquez le montant HT ainsi que l'objet et les commentaires qui apparaîtront sur l'acompte",
            final_invoice: "Facture de solde",
            enter_subject_comments: "Indiquez le sujet et les commentaires qui apparaîtront sur la facture de solde",
            duplicated: "Dupliqué",
            edit: "Modifier",
            more_actions: "Plus d'actions",
            created_delivery_form: "Formulaire de livraison créé",
            edit_menu: {
                modify_quote: "Modifier le devis",
                duplicate_quote: "Dupliquer le devis",
                create_delivery_form: "Créer un bon de livraison",
            },
            delete: "Supprimer",
            finalize: "Finaliser",
            issue_down_payment: "Émettre un acompte",
            issue_final_invoice: "Émettre la facture de solde",
            delete_popup: {
                heading: "Supprimer le devis",
                description: "Voulez-vous supprimer ce devis ?",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
            },
            finalize_popup: {
                heading: "Finaliser le devis ?",
                description: "Le devis pourra être modifié jusqu'à l'acception ou le refus de celui-ci !",
                cancel_btn: "Annuler",
                finalize_btn: "Finaliser",
            },
            status_popup: {
                heading: "Changer le statut ?",
                description: "Le devis ne sera plus modifiable après avoir accepté ou refusé celui-ci !",
                cancel_btn: "Annuler",
                confirm_btn: "Confirmer",
            },
            modify_invoice: "Modifier la facture",
            status_btn: {
                issue_invoice: "Émettre une facture",
                deposit_invoice: "Facture d'acompte",
                final_invoice: "Facture de solde",
            },
            summary: {
                client: "Clients",
                total_excl_vat: "Total HT",
                total_incl_vat: "Total TTC",
                date: "Date",
                overview: "Récapitulatif",
                delay_expiration: "Délai de validité",
                days: "Jours",
                urssaf_taxes: "Cotisations URSSAF",
            },
            toolbar: {
                view_detail_options: {
                    events: "Événements",
                    payments: "Paiements",
                    credit_notes: "Avoirs",
                    attachments: "Pièces jointes",
                },
                download: "Télécharger",
                print: "Imprimer",
                send: "Envoyer",
                hide_notes: "Masquer les notes",
                notes: "Remarques",
                show_more: "Voir plus",
                send_modal: {
                    error: {
                        email: "L'email est requis",
                        title: "L'objet est requis",
                        body: "Le corps est requis",
                        invalid_emails: "Adresses e-mail invalides",
                    },
                    sent: "Envoyé",
                    subject: "Objet",
                    email: "Adresse e-mail",
                    multi_email_info: "Vous pouvez envoyer à plusieurs destinataires en séparant les e-mails par une virgule",
                    send: "Envoyer",
                    cancel_btn: "Annuler",
                    send_btn: "Envoyer",
                },
            },
            more_details_panel: {
                events: {
                    history: "Historique",
                    timeline_of_events: "Chronologie des événements",
                },
                attachments: {
                    attachments: "Pièces jointes",
                    select_files: "Sélectionnez les fichiers",
                }
            },
            pdf: {
                loading_pdf: "Chargement du PDF",
                of: "de",
            },
            down_pay_modal: {
                error: {
                    amount_min: "Montant minimum autorisé {{amount}}",
                    amount_max: "Montant maximum autorisé {{amount}}",
                    amount: "Le montant est requis",
                },
                summary: "Résumé",
                total_down_payments: "Montant total des acomptes :",
                remainder_to_invoiced: "Restant à facturer :",
                amount_tax_symbol: {
                    false: "Montant HT (en {{symbol}})",
                    true: "Montant TTC (en {{symbol}})",
                },
                including_taxes: "TTC",
                excluding_taxes: "HT",
                subject: "Objet",
                comments: "Commentaires",
                create_down_payment_draft: "Créer le document en brouillon",
                cancel_btn: "Annuler",
                price_labels: {
                    vat_incl: "TTC",
                    vat_excl: "HT",
                },
                price_format: "Format de prix",
            },
            edit_popup: {
                error: {
                    activity: "L'activité est requise",
                    title: "Le titre est requis",
                    quantity: "La quantité est requise",
                    country: "Le pays est requis",
                    price: "Le prix est requis",
                    payment_gateway: "Passerelle de paiement requise",
                },
                updated: "Mis à jour",
                sales_type: "Type de vente",
                country_of_origin: "Pays de départ des articles",
                choose_a_country: "Choisissez un pays",
                tracking_number: "Numéro de suivi colis",
                purchase_price: "Prix d'achat",
                activity: "Activités",
                cancel_btn: "Annuler",
                save_btn: "Enregistrer",
                accept_online_payment: "Activer le paiement en ligne",
                select_payment_gateways: "Sélectionnez les services de paiement",
                same_type_payment_already_selected: "Déjà le même type sélectionné",
                connect_payment_gateway: "Connectez un service pour accepter les paiements en ligne",
                connect_btn: "Connecter",
            },
        },
        edit: {
            status_tag: {
                pending: "En attente",
                accepted: "Accepté",
                declined: "Refusé",
                closed: "Clôturé",
                draft: "Brouillon",
                null: ""
            },
            new_quote: "Nouveau devis",
            edit_quote: "Modifier le devis",
            error: {
                language: "La langue est requise",
                currency: "La devise est requise",
                activity: "L'activité est requise",
                template: "Le modèle est requis",
                last_name: "Le nom est requis",
                country: "Le pays est requis",
                company_name: "Nom de l'entreprise requis",
                address: "L'adresse est requise",
                city: "La ville est requise",
                client: "Le client est requis",
                zip: "Le code postal est requis",
                title: "Le titre est requis",
                quantity: "La quantité est requise",
                price: "Le prix est requis",
                price_min: "Prix minimum autorisé {{amount}}",
                tree: "Au minimum un article est requis",
                expiration_date: "La date d'expiration est requise",
                min_tomorrow: "Min demain autorisé",
                payment_gateway: "Passerelle de paiement requise",
            },
            restore_version: "Vous avez des éléments non sauvegardés",
            draft_actions: {
                apply: "Restaurer",
                discard: "Ignorer",
            },
            created: "Devis créé",
            updated: "Devis mis à jour",
            saved: "Enregistré",
            cancel_btn: "Annuler",
            save_btn: "Enregistrer",
            confirm_rule_popup: {
                heading: "Mise à jour de la TVA",
                description: "Le taux de TVA doit être changé en conformité avec les informations présentes sur la facture, voulez-vous le modifier ?",
                yes_btn: "Oui",
                no_btn: "Non",
            },
            vat_section: {
                price_labels: {
                    vat_incl: "TTC",
                    vat_excl: "HT",
                },
                vat_labels: {
                    no: "Non",
                    yes: "Oui",
                },
                vat_exempt: "Exonération de TVA",
                price_format: "Format de prix",
                exemption_text: "Message d'exonération",
                exemption_placeholder: "TVA non applicable...",
            },
            filter_section: {
                language_input_label: "Langue",
                currency_input_label: "Devise",
                activity_input_label: "Activité",
                select_template_input_label: "Modèle",
                subject_input_label: "Objet",
                invoice_title: "Intitulé de la facture...",
                expiration_date_input_label: "Date d'expiration",
            },
            pdf_section: {
                loading_pdf: "Chargement du PDF",
                of: "de",
            },
            client_section: {
                client: "Client",
                select_client_input_label: "Sélectionnez un client",
                no_client_found: "Aucun client trouvé",
                use_another_shipping: "Adresse de livraison différente",
                another_shipping: {
                    company_input_label: "Entreprise",
                    vat_number_input_label: "TVA intracommunautaire",
                    registration_number_input_label: "SIREN",
                    last_name_input_label: "Nom",
                    first_name_input_label: "Prénom",
                    address_input_label: "Adresse",
                    address_supplementary_input_label: "Complément d'adresse",
                    zip_input_label: "Code postal",
                    city_input_label: "Ville",
                    country_input_label: "Pays",
                    client_type_labels: {
                        individual: "Particulier",
                        business: "Entreprise",
                    },
                }
            },
            article_section: {
                articles: "Articles",
                no_article_found: "Aucun article trouvé",
                loading: "Chargement...",
                choose_article: "Sélectionnez un article",
                search_articles: "Rechercher un article",
                edit_line: "Modifier la ligne",
                add_new_line: "Ajouter un article",
                edit_popup: {
                    ref: "Réf",
                    title: "Titre",
                    description: "Description",
                    insert_tag: "Insérez une balise :",
                    tags: {
                        start_date: "Date de début",
                        end_date: "Date de fin",
                        start_month: "Mois de début",
                        end_month: "Mois de fin",
                        start_year: "Année de début",
                        end_year: "Fin d'année",
                    },
                    amount: "Montant",
                    vat: "TVA",
                    no_results_found: "Aucun résultat trouvé.",
                    discount: "Remise",
                    qte: "QTE",
                    unit: "Unité",
                    sales_type: "Type de vente",
                    country_of_origin: "Pays de départ des articles",
                    choose_a_country: "Choisissez un pays",
                    tracking_number: "Numéro de suivi colis",
                    second_hand_good: "Article d'occasion",
                    purchase_price: "Prix d'achat",
                    save_article: "Enregistrer l'article",
                    save_btn: "Enregistrer",
                    cancel_btn: "Annuler",
                    unit_labels: {
                        piece: "Pièce",
                        hour: "Heure",
                        day: "Jour",
                        meter: "Mètre",
                        kilometer: "Kilomètre",
                        gram: "Gramme",
                        kilogram: "Kilogramme",
                        square_meter: "Mètre carré",
                    }
                }
            },
            discount_section: {
                discount_shipping: "Réduction / Frais de port",
                discount: "Remise",
                shipping_cost: "Frais de port",
                vat: "TVA",
                no_results_found: "Aucun résultat trouvé.",
            },
            payment_section: {
                payment: "Paiement",
                payment_method: "Modes de paiement",
                accept_online_payment: "Activer le paiement en ligne",
                select_payment_gateways: "Sélectionnez les services de paiement",
                same_type_payment_already_selected: "Déjà le même type sélectionné",
                connect_payment_gateway: "Connectez un service pour accepter les paiements en ligne",
                connect_btn: "Connecter",
                payment_methods: {
                    bank_transfer: "Virement",
                    cash: "Espèces",
                    check: "Chèque",
                    cesu: "Cesu",
                    direct_debit: "Prélèvement",
                    credit_card: "Carte bancaire",
                    other: "Autre",
                    paypal: "PayPal",
                    gift_card: "Carte cadeau",
                    null: ""
                },
                payment_delay: "Délai de paiement",
                penalty_rate: "Intérêts retard",
                rebate: "Escompte",
                payment_terms: "Ajouter les modalités de paiement",
                payment_delay_labels: {
                    upon_invoice: "A réception",
                    upon_order: "A la commande",
                    upon_15_net: "15 jours nets",
                    upon_30_net: "30 jours nets",
                    upon_45_net: "45 jours nets",
                    upon_60_net: "60 jours nets",
                    upon_30_end: "30 jours nets fin de mois",
                    upon_45_end: "45 jours nets fin de mois",
                    upon_30_end_offset_10: "30 jours nets fin de mois, le 10",
                    upon_30_end_offset_20: "30 jours nets fin de mois, le 20",
                    none: "Aucun",
                }
            },
            option_section: {
                options: "Options",
                comments: "Commentaires",
                tracking_numbers: "Numéros de suivi colis",
                shipping_companies: "Transporteurs",
            },
            attachment_section: {
                attachments: "Pièces jointes",
                latest_added_attachments: "Dernières pièces jointes ajoutées",
            }
        }
    },
    quote: {
        history: {
            email: "Devis envoyé à {{email}}",
            emailSent: "Devis envoyé à {{email}}",
            issued: "Devis émis",
            billed: "Devis soldé",
            downPayment: "Acompte de {{total}} {{currency}} créé",
            emailRead: "Devis consulté",
            modified: "Devis modifié",
            accepted: "Accepté",
            declined: "Refusé"
        },
    },
    delivery_notes: {
        nav_title: "Bons de livraison",
        list: {
            delivery_notes: "Bons de livraison",
            list_delivery_notes: "Bons de livraison",
            new_delivery_note: "Nouveau bon de livraison",
            all_activity: "Tous",
            deleted: "Supprimé",
            delete: "Supprimer",
            selected: "Sélectionné(s)",
            not_found: {
                heading: "Éditez ici les bons de livraison à présenter à vos clients",
                sub_heading: "Ces documents permettent de prouver la bonne livraison des biens",
            },
            search: "Chercher",
            delete_popup: {
                heading: "Supprimer les bons de livraison",
                description: "Voulez-vous supprimer les bons de livraison ?",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer"
            },
            date_filters: {
                today: "Aujourd'hui",
                yesterday: "Hier",
                this_week: "Cette semaine",
                last_week: "La semaine dernière",
                this_month: "Ce mois",
                last_month: "Le mois dernier",
                last_30_days: "Les 30 derniers jours",
                last_90_days: "90 derniers jours",
                last_12_months: "12 derniers mois",
                this_year: "Cette année",
                last_year: "L'année dernière",
                absolute: "Absolue",
                relative: "Relative",
                between: "Entre",
                is_after: "Est après",
                is_before: "Est avant",
                all_period: "Période entière",
            },
            tabs: {
                all: "Tous",
                issued: "Émis",
                draft: "Brouillon",
            },
            toolbar: {
                activity: "Activité",
                start_date: "Date de début",
                end_date: "Date de fin",
                date: "Date",
                clear: "Effacer",
            },
            columns: {
                date: "Date",
                reference: "Référence",
                client: "Client",
                subject: "Objet",
                status: "Statut",
                status_tag: {
                    issued: "Émis",
                    draft: "Brouillon",
                    null: ""
                },
            },
        },
        view: {
            deleted: "Supprimé",
            issued: "Bons de livraison émise",
            delivery_note_view: "Voir le bon de livraison",
            status_tag: {
                issued: "Émis",
                draft: "Brouillon",
                null: ""
            },
            edit: "Modifier",
            delete: "Supprimer",
            finalize: "Finaliser",
            summary: {
                client: "Clients",
                date: "Date",
            },
            toolbar: {
                view_detail_options: {
                    events: "Événements",
                    payments: "Paiements",
                    credit_notes: "Avoirs",
                    attachments: "Pièces jointes",
                },
                download: "Télécharger",
                print: "Imprimer",
                send: "Envoyer",
                hide_notes: "Masquer les notes",
                notes: "Remarques",
                show_more: "Voir plus",
                send_modal: {
                    error: {
                        email: "L'email est requis",
                        title: "L'objet est requis",
                        body: "Le corps est requis",
                        invalid_emails: "Adresses e-mail invalides",
                    },
                    sent: "Envoyé",
                    subject: "Objet",
                    email: "Adresse e-mail",
                    multi_email_info: "Vous pouvez envoyer à plusieurs destinataires en séparant les e-mails par une virgule",
                    send: "Envoyer",
                    cancel_btn: "Annuler",
                    send_btn: "Envoyer",
                },
            },
            more_details_panel: {
                events: {
                    history: "Historique",
                    timeline_of_events: "Chronologie des événements",
                },
                attachments: {
                    attachments: "Pièces jointes",
                    select_files: "Sélectionnez les fichiers",
                }
            },
            pdf: {
                loading_pdf: "Chargement du PDF",
                of: "de",
            },
            finalize_popup: {
                heading: "Finaliser le bon de livraison ?",
                description: "Le bon de livraison émis ne sera plus modifiable, êtes-vous sûr ?",
                cancel_btn: "Annuler",
                finalize_btn: "Finaliser",
            },
            delete_popup: {
                heading: "Supprimer le bon de livraison",
                description: "Voulez-vous supprimer ce bon de livraison ?",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
            },
        },
        edit: {
            new_delivery_note: "Nouveau bon de livraison",
            status_tags: {
                issued: "Émis",
                draft: "Brouillon",
                null: ""
            },
            edit_not_allowed: "Modification non autorisée",
            error: {
                language: "La langue est requise",
                currency: "La devise est requise",
                activity: "L'activité est requise",
                template: "Le modèle est requis",
                last_name: "Le nom est requis",
                country: "Le pays est requis",
                company_name: "Nom de l'entreprise requis",
                address: "L'adresse est requise",
                city: "La ville est requise",
                client: "Le client est requis",
                zip: "Le code postal est requis",
                title: "Le titre est requis",
                quantity: "La quantité est requise",
                price: "Le prix est requis",
                price_min: "Prix minimum autorisé {{amount}}",
                tree: "Au minimum un article est requis",
            },
            restore_version: "Vous avez des éléments non sauvegardés",
            created: "Bon de livraison créé",
            updated: "Bon de livraison mis à jour",
            edit_delivery_note: "Modifier le bon de livraison",
            cancel_btn: "Annuler",
            save_btn: "Enregistrer",
            draft_actions: {
                apply: "Restaurer",
                discard: "Ignorer",
            },
            filter_section: {
                language_input_label: "Langue",
                currency_input_label: "Devise",
                activity_input_label: "Activité",
                select_template_input_label: "Modèle",
                subject_input_label: "Objet",
                invoice_title: "Intitulé de la facture...",
                expiration_date_input_label: "Date d'expiration",
            },
            pdf_section: {
                loading_pdf: "Chargement du PDF",
                of: "de",
            },
            client_section: {
                client: "Client",
                select_client_input_label: "Sélectionnez un client",
                no_client_found: "Aucun client trouvé",
                use_another_shipping: "Adresse de livraison différente",
                another_shipping: {
                    company_input_label: "Entreprise",
                    vat_number_input_label: "TVA intracommunautaire",
                    registration_number_input_label: "SIREN",
                    last_name_input_label: "Nom",
                    first_name_input_label: "Prénom",
                    address_input_label: "Adresse",
                    address_supplementary_input_label: "Complément d'adresse",
                    zip_input_label: "Code postal",
                    city_input_label: "Ville",
                    country_input_label: "Pays",
                    client_type_labels: {
                        individual: "Particulier",
                        business: "Entreprise",
                    },
                }
            },
            article_section: {
                articles: "Articles",
                no_article_found: "Aucun article trouvé",
                loading: "Chargement...",
                choose_article: "Sélectionnez un article",
                search_articles: "Rechercher un article",
                edit_line: "Modifier la ligne",
                add_new_line: "Ajouter un article",
                edit_popup: {
                    ref: "Réf",
                    title: "Titre",
                    description: "Description",
                    insert_tag: "Insérez une balise :",
                    tags: {
                        start_date: "Date de début",
                        end_date: "Date de fin",
                        start_month: "Mois de début",
                        end_month: "Mois de fin",
                        start_year: "Année de début",
                        end_year: "Fin d'année",
                    },
                    amount: "Montant",
                    vat: "TVA",
                    no_results_found: "Aucun résultat trouvé.",
                    discount: "Remise",
                    qte: "QTE",
                    unit: "Unité",
                    sales_type: "Type de vente",
                    country_of_origin: "Pays de départ des articles",
                    choose_a_country: "Choisissez un pays",
                    tracking_number: "Numéro de suivi colis",
                    second_hand_good: "Article d'occasion",
                    purchase_price: "Prix d'achat",
                    save_article: "Enregistrer l'article",
                    save_btn: "Enregistrer",
                    cancel_btn: "Annuler",
                    unit_labels: {
                        piece: "Pièce",
                        hour: "Heure",
                        day: "Jour",
                        meter: "Mètre",
                        kilometer: "Kilomètre",
                        gram: "Gramme",
                        kilogram: "Kilogramme",
                        square_meter: "Mètre carré",
                    }
                }
            },
            option_section: {
                options: "Options",
                comments: "Commentaires",
                tracking_numbers: "Numéros de suivi colis",
                shipping_companies: "Transporteurs",
            },
            attachment_section: {
                attachments: "Pièces jointes",
                latest_added_attachments: "Dernières pièces jointes ajoutées",
            }
        }
    },
    deliveryForm: {
        history: {
            email: "Bons de livraison envoyée à {{email}}",
            emailSent: "Bons de livraison envoyée à {{email}}",
            paymentAdded: "Paiement de {{total}} {{currency}} ajouté",
            paymentRemoved: "Paiement de {{total}} {{currency}} supprimé",
            issued: "Bons de livraison émise",
            backIssued: "Bons de livraison non soldée",
            cancelled: "Bons de livraison annulée",
            asset: "La Bons de livraison a fait l'object d'un avoir",
            created: "Bons de livraison créée",
            paid: "Bons de livraison soldée",
            issuedAsset: "Avoir créé",
            paymentGift: "Carte cadeau de {{total}} {{currency}} utilisée",
            emailRead: "Bons de livraison consultée"
        },
    },
    expenses: {
        nav_title: "Dépenses",
        list: {
            delete: "Supprimer",
            export_popup: {
                error: {
                    invalid_email: "L'email est invalide",
                    email: "L'e-mail est requis",
                },
                export: "Exporter",
                export_msg: "L'export sera envoyé dans votre boîte e-mail dans quelques instants",
                export_as_pdf: "Format PDF",
                export_as_csv: "Format CSV",
                heading: "Registre des achats",
                sub_heading: "Ajustez les critères pour télécharger vos dépenses",
                export_x_expenses: "Exporter {{count}} dépenses",
                date_filters: {
                    today: "Aujourd'hui",
                    yesterday: "Hier",
                    this_week: "Cette semaine",
                    last_week: "La semaine dernière",
                    this_month: "Ce mois",
                    last_month: "Le mois dernier",
                    last_30_days: "Les 30 derniers jours",
                    last_90_days: "90 derniers jours",
                    last_12_months: "12 derniers mois",
                    this_year: "Cette année",
                    last_year: "L'année dernière",
                },
                reference_date_options: {
                    date_of_issue: "Date d'émission",
                    date_of_sale: "Date de vente",
                    payment_date: "Date de paiement",
                },
                billing_period: "Période de facturation",
                more_filters: "Plus de filtres",
                less_filters: "Moins de filtres",
                from_input_label: "Du",
                to_input_label: "Au",
                loading: "Chargement...",
                not_found: "Aucun fournisseur trouvé",
                supplier_input_label: "Fournisseur",
                no_activity_found: "Aucune activité trouvée",
                activity_input_label: "Activité",
                filter_by: "Filtrer par",
                attachments: "Pièces jointes",
                include_attachments: "Inclure les pièces jointes",
                recipient: "Destinataire",
                email_input_label: "Email",
                cancel_btn: "Annuler",
            },
            import_popup: {
                success: "Succès",
                heading: "Importer des dépenses",
                sub_heading: "Sélectionnez votre fichier contenant la liste des dépenses à importer",
                validate_btn: "Valider",
                import_alert: "Pour en savoir plus sur les étapes à suivre, veuillez lire cet article :",
                cancel_btn: "Annuler",
                drop_csv_here: "Déposez votre fichier CSV ici",
            },
            nav_title: "Liste des dépenses",
            heading: "Dépenses",
            import_btn: "Importer",
            new_expense_btn: "Nouvelle dépense",
            all: "Tous",
            deleted: "Supprimé",
            not_found: {
                heading: "Enregistrez ici les dépenses liées à votre activité",
                sub_heading: "Les dépenses vous permettent de suivre la rentabilité de votre entreprise",
            },
            search_invoice_number: "Rechercher une dépense...",
            selected: "Sélectionné(s)",
            delete_popup: {
                heading: "Supprimer les dépenses ?",
                description: "Cette opération est irréversible !",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
            },
            tabs: {
                all: "Tous",
                with_attachment: "Avec justificatif",
                without_attachment: "Sans justificatif",
            },
            toolbar: {
                activity: "Activité",
                start_date: "Date de début",
                end_date: "Date de fin",
                date: "Date",
                clear: "Effacer",
            },
            date_filters: {
                today: "Aujourd'hui",
                yesterday: "Hier",
                this_week: "Cette semaine",
                last_week: "La semaine dernière",
                this_month: "Ce mois",
                last_month: "Le mois dernier",
                last_30_days: "Les 30 derniers jours",
                last_90_days: "90 derniers jours",
                last_12_months: "12 derniers mois",
                this_year: "Cette année",
                last_year: "L'année dernière",
                absolute: "Absolue",
                relative: "Relative",
                between: "Entre",
                is_after: "Est après",
                is_before: "Est avant",
                all_period: "Période entière",
            },
            columns: {
                date: "Date",
                supplier: "Fournisseur",
                label: "Libellé",
                total_exc_taxes: "Montant HT",
                total_inc_taxes: "Montant TTC",
                payment_method: "Mode de paiement",
                payment_methods: {
                    bank_transfer: "Virement",
                    cash: "Espèces",
                    check: "Chèque",
                    cesu: "Cesu",
                    direct_debit: "Prélèvement",
                    credit_card: "Carte bancaire",
                    other: "Autre",
                    paypal: "PayPal",
                    gift_card: "Carte cadeau",
                    null: ""
                },
                attachments: "Pièces jointes",
                yes: "Yes",
                no: "No"
            },
        },
        edit: {
            error: {
                not_found: "Aucune dépense trouvée",
                date: "La date est requise",
                activity: "L'activité est requise",
                currency: "La devise est requise",
                amount: "Le montant est requis",
                supplier: "Le fournisseur est requis",
                method: "Le mode de paiement est requis",
                type_required: "La catégorie est requise"
            },
            created: "Dépense créée",
            updated: "Dépense mise à jour",
            deleted: "Supprimé",
            heading: "Dépense",
            cancel_btn: "Annuler",
            delete_btn: "Supprimer",
            update_btn: "Enregistrer",
            create_btn: "Créer",
            info_alert: "Cette dépense est liée à une transaction bancaire et certains éléments ne peuvent pas être modifiés",
            delete_popup: {
                heading: "Supprimer les dépenses ?",
                description: "Cette opération est irréversible !",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
            },
            date_input_label: "Date",
            activity_input_label: "Activité",
            supplier_input_label: "Fournisseur",
            not_found_input_label: "Aucun fournisseur trouvé",
            payment_mode_input_label: "Mode de paiement",
            currency_input_label: "Devise",
            amount_input_label: "Montant",
            vat_rate_input_label: "Taux TVA",
            amount_excl_vat_input_label: "Montant HT",
            no_results_found: "Aucun résultat trouvé.",
            toggle_vat_labels: {
                vat_rate: "Taux TVA",
                amount_excl_vat: "Montant HT",
            },
            type_input_label: "Catégorie",
            label_input_label: "Libellé",
            reference_input_label: "Référence",
            payment_methods: {
                bank_transfer: "Virement",
                cash: "Espèces",
                check: "Chèque",
                cesu: "Cesu",
                direct_debit: "Prélèvement",
                credit_card: "Carte bancaire",
                other: "Autre",
                paypal: "PayPal",
                gift_card: "Carte cadeau",
                null: ""
            },
            notes_input_label: "Remarques",
            documents: "Documents",
            select_files: "Sélectionnez les fichiers",
        }
    },
    recurring_expenses: {
        nav_title: "Récurrentes",
        list: {
            heading: "Dépenses récurrentes",
            new_expense_btn: "Nouvelle dépense récurrente",
            deleted: "Supprimé",
            delete_fail: "Impossible de supprimer",
            disabled: "Dépense récurrente désactivée",
            enabled: "Dépense récurrente activée",
            not_found: {
                heading: "Enregistrez ici les dépenses récurrentes pour vos achats réguliers",
                sub_heading: "Les dépenses seront automatiquement créées avec les information indiquées, à la date programmée",
            },
            supplier: "Fournisseur",
            frequency_title: "Fréquence",
            duration_title: "Durée",
            next_exp: "Dépense suivante",
            amount: "Montant",
            status: "Statut",
            edit_btn: "Modifier",
            delete_popup: {
                heading: "Attention",
                description: "Voulez-vous vraiment supprimer la dépense récurrente ?",
                cancel_btn: "Annuler",
                delete_btn: "Effacer",
            },
            edit_menu: {
                disable: "Désactiver",
                enable: "Activer",
                modify: "Modifier",
                delete: "Supprimer",
            },
            frequency: {
                weekly: "Hebdomadaire",
                monthly: "Mensuel",
                quarterly: "Trimestriel",
                yearly: "Annuel",
                custom: "Personnalisé",
            },
            duration: {
                not_limited: "Non limité",
                stop_after_date: "Arrêt après une date",
                until: "Jusqu'au"
            },
            status_labels: {
                enabled: "Actif",
                disabled: "Inactif"
            },
        },
        edit: {
            error: {
                not_found: "Aucune dépense récurrente trouvée",
                invalid_date: "La date n'est pas valide",
                date: "La date est requise",
                activity: "L'activité est requise",
                type: "Le type est requis",
                currency: "La devise est requise",
                amount: "Le montant est requis",
                supplier: "Le fournisseur est requis",
                method: "Le mode de paiement est requis",
                min_start_date: "La date de fin doit être postérieure à la date de début",
                occurrence_type: "Le type est requis",
                occurrence_value: "La valeur est requise",
            },
            created: "Dépense récurrente créée",
            updated: "Dépense récurrente mise à jour",
            expenses: "Dépenses récurrentes",
            cancel_btn: "Annuler",
            update_btn: "Enregistrer",
            create_btn: "Créer",
            expense: "Dépense",
            frequency: {
                weekly: "Hebdomadaire",
                monthly: "Mensuel",
                quarterly: "Trimestriel",
                yearly: "Annuel",
                custom: "Personnalisé",
            },
            duration: {
                not_limited: "Non limité",
                stop_after_date: "Arrêt après une date",
                until: "Jusqu'au"
            },
            dwmy: {
                day: "Jours",
                week: "Semaines",
                month: "Mois",
                year: "Ans",
            },
            settings: "Paramètres",
            date_first_invoice: "Date de la 1ère facture",
            frequency_title: "Fréquence",
            duration_title: "Durée",
            send_invoice: "Envoyer les factures",
            date_input_label: "Date",
            activity_input_label: "Activité",
            supplier_input_label: "Fournisseur",
            not_found_input_label: "Aucun fournisseur trouvé",
            payment_mode_input_label: "Mode de paiement",
            currency_input_label: "Devise",
            amount_input_label: "Montant",
            vat_rate_input_label: "Taux TVA",
            amount_excl_vat_input_label: "Montant HT",
            no_results_found: "Aucun résultat trouvé.",
            toggle_vat_labels: {
                vat_rate: "VAT Rate",
                amount_excl_vat: "Montant HT",
            },
            type_input_label: "Catégorie",
            label_input_label: "Libellé",
            reference_input_label: "Référence",
            payment_methods: {
                bank_transfer: "Virement",
                cash: "Espèces",
                check: "Chèque",
                cesu: "Cesu",
                direct_debit: "Prélèvement",
                credit_card: "Carte bancaire",
                other: "Autre",
                paypal: "PayPal",
                gift_card: "Carte cadeau",
                null: ""
            },
            notes_input_label: "Remarques",
            documents: "Documents",
            select_files: "Sélectionnez les fichiers",
        }
    },
    expense_types: {
        nav_title: "Catégories",
        title: "Catégories de dépense",
        add_new_type_btn: "Nouvelle catégorie",
        expense_types: "Catégories",
        edit_type: "Modifier la catégorie",
        create_type: "Créer une catégorie",
        type_name: "Nom de la catégorie",
        created: "Catégorie créée",
        updated: "Catégorie mise à jour",
        cancel_btn: "Annuler",
        save_btn: "Enregistrer",
        error: {
            same_name: "Cette catégorie existe déjà",
            name: "Le nom est requis",
        },
        delete_popup: {
            heading: "Attention",
            description: "Voulez-vous vraiment supprimer la catégorie ?",
            cancel_btn: "Annuler",
            delete_btn: "Supprimer",
        },
        not_found: {
            heading: "Enregistrez ici les catégories de dépense",
            sub_heading: "Cela vous permet de classifier vos achats et d'avoir une meilleure visibilité sur votre marge"
        },
    },
    banks: {
        nav_title: "Banques",
        banks: "Banques",
        add_bank_btn: "Ajouter une banque",
        refreshing_transactions: "Actualisation des dernières transactions",
        not_found: {
            heading: "Vous n'avez aucune banque connectée",
            sub_heading: "Connectez votre banque et importez vos recettes et dépenses en un clic",
        },
        reconnect: "Se reconnecter",
        current_balance: "Solde actuel",
        last_sync_time: "Dernière synchronisation",
        pending_count: "{{count}} en attente",
        transactions: "transactions",
        setting_modal: {
            error: {
                required: "Au moins un compte doit être activé"
            },
            deleted: "Supprimé",
            bank_settings: "Paramètres bancaires",
            bank_accounts_sync: "Comptes bancaires à synchroniser",
            cancel_btn: "Annuler",
            delete_btn: "Supprimer",
            save_btn: "Enregistrer",
        },
        list: {
            deleted: "Supprimé",
            ignored: "Ignoré",
            restored: "Restauré",
            delete_payment: "Supprimer le paiement",
            want_to_delete_payment: "Voulez-vous supprimer le paiement ?",
            delete_expense: "Supprimer la dépense",
            want_to_delete_expense: "Voulez-vous supprimer une dépense ?",
            search_invoice_number: "Rechercher une dépense...",
            selected: "Sélectionné(s)",
            delete: "Supprimer",
            tabs: {
                pending: "Transactions en attente",
                associated: "Transactions associées",
                ignored: "Transactions ignorées",
            },
            columns: {
                date: "Date",
                type: "Type",
                types: {
                    expense: "Dépense",
                    income: "Recette",
                    null: "",
                },
                amount: "Montant",
                description: "Description",
                edit: "Modifier",
                delete: "Supprimer",
                restore: "Restaurer",
                ignore: "Ignorer",
                save: "Enregistrer",
                pay_bill: "Payer une facture",
                create_income: "Créer une recette",
                associate: "Associer",
            },
            delete_popup: {
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
            },
            ignore_popup: {
                heading: "Attention",
                sub_heading: "Les transactions sélectionnées seront ignorées.",
                cancel_btn: "Annuler",
                delete_btn: "Ignorer",
            },
            pay_modal: {
                transaction_link_desc: "Cette recette est liée à une transaction bancaire et certains éléments ne sont pas modifiables",
                error: {
                    amount: "Le montant est requis",
                    date: "La date est requise",
                    method: "La méthode est requise",
                    invoice: "Une facture est requise",
                    amount_min_max: "Doit être entre {{min}} et {{max}}",
                },
                create_payment_success: "Paiement enregistré",
                update_payment_success: "Paiement mis à jour",
                invoice_to_xxx: "Facture à {{name}}",
                payment: "Paiement",
                loading: "Chargement...",
                no_invoice: "Aucune facture trouvée",
                invoice_to_paid: "Facture à payer",
                date: "Date",
                amount: "Montant",
                label: "Libellé",
                payment_mode: "Mode de paiement",
                notes: "Remarques",
                note_by_cheque: " N° chèque, client,...",
                cancel_btn: "Annuler",
                view_invoice_btn: "Voir la facture",
                view_credit_note_btn: "Afficher l'avoir",
                save_btn: "Enregistrer",
                payment_methods: {
                    bank_transfer: "Virement",
                    cash: "Espèces",
                    check: "Chèque",
                    cesu: "Cesu",
                    direct_debit: "Prélèvement",
                    credit_card: "Carte bancaire",
                    other: "Autre",
                    paypal: "PayPal",
                    gift_card: "Carte cadeau",
                    null: ""
                },
            },
            toolbar: {
                date: "Date",
                start_date: "Start date",
                end_date: "End date",
                clear: "Effacer",
            },
            date_filters: {
                today: "Aujourd'hui",
                yesterday: "Hier",
                this_week: "Cette semaine",
                last_week: "La semaine dernière",
                this_month: "Ce mois",
                last_month: "Le mois dernier",
                last_30_days: "Les 30 derniers jours",
                last_90_days: "90 derniers jours",
                last_12_months: "12 derniers mois",
                this_year: "Cette année",
                last_year: "L'année dernière",
                absolute: "Absolue",
                relative: "Relative",
                between: "Entre",
                is_after: "Est après",
                is_before: "Est avant",
                all_period: "Période entière",
            },
        }
    },
    bank_rules: {
        nav_title: "Règles",
        list: {
            rules: "Règles",
            banks_rules: "Règles bancaires",
            new_rule_btn: "Nouvelle règle",
            deleted: "Supprimé",
            disabled: "Règle désactivée",
            enabled: "Règle activée",
            not_found: {
                heading: "Enregistrez ici des règles afin de catégoriser vos transactions bancaires",
                sub_heading: "Vous pourrez gagner du temps en associant automatiquement vos recettes et dépenses issues de vos comptes bancaires",
            },
            name: "Nom",
            type: "Type",
            action: "Action",
            status: "Statut",
            types: {
                expense: "Dépense",
                income: "Recette",
                null: "",
            },
            ignored: "Ignoré",
            associated_transaction: "Associer la transaction",
            delete_popup: {
                heading: "Attention",
                description: "Voulez-vous vraiment supprimer la règle bancaire ?",
                cancel_btn: "Annuler",
                delete_btn: "Effacer",
            },
            status_labels: {
                enabled: "Activé",
                disabled: "Désactivé",
            },
            edit_menu: {
                disable: "Désactiver",
                enable: "Activer",
                modify: "Modifier",
                delete: "Supprimer",
                edit: "Modifier",
            }
        },
        edit: {
            updated: "Règle mise à jour",
            created: "Règle créée",
            rule_management: "Gestion des règles",
            cancel_btn: "Annuler",
            update_btn: "Enregistrer",
            create_btn: "Créer",
            error: {
                not_found: "Pas trouvé",
                name: "Le nom est requis",
                type: "Le type est requis",
                back_account: "Compte bancaire requis",
                operator: "L'opérateur est requis",
                value: "Valeur est requise",
                method: "Le mode de paiement est requis",
                tax_rate: "Le taux de TVA est requis",
                activity: "L'activité est requise",
                supplier: "Le fournisseur est requis",
                client: "Le client est requis",
                title: "Le titre est requis",
                country: "Le pays est requis",
            },
            inc_exp_labels: {
                expense: "Dépense",
                income: "Recette",
            },
            name: "Nom",
            operation: "Opération",
            apply_all_accounts: "Appliquer à tous les comptes",
            apply_labels: {
                yes: "Oui",
                no: "Non"
            },
            list_accounts: "Liste des comptes",
            terms: "Conditions",
            validation_conditions: "Valider toutes les conditions",
            matching_labels: {
                yes: "Oui",
                no: "Non"
            },
            type_of_payment: "Type de paiement",
            and: "ET",
            or: "OU",
            add_line: "Ajouter une ligne",
            action: "Action",
            ignore_transaction: "Ignorer la transaction",
            action_labels: {
                yes: "Oui",
                no: "Non"
            },
            payment_method: "Mode de paiement",
            nature_description: "Nature/Descriptif",
            activity: "Activité",
            client: "Client",
            no_client_found: "Aucun client trouvé",
            vat_liable: "Assujetti à la TVA",
            taxes_options: {
                yes: "Oui",
                no: "Non"
            },
            title: "Titre",
            vat: "TVA",
            no_results_found: "Aucun résultat trouvé.",
            type: "Catégorie",
            supplier: "Fournisseur",
            country_of_origin: "Pays de départ des articles",
            choose_a_country: "Choisissez un pays",
            payment_methods: {
                bank_transfer: "Virement",
                cash: "Espèces",
                check: "Chèque",
                cesu: "Cesu",
                direct_debit: "Prélèvement",
                credit_card: "Carte bancaire",
                other: "Autre",
                paypal: "PayPal",
                gift_card: "Carte cadeau",
                null: ""
            },
        }
    },
    clients: {
        nav_title: "Clients",
        list: {
            nav_title: "Clients",
            list_clients: "Clients",
            export_btn: "Exporter",
            import_btn: "Importer",
            new_client_btn: "Nouveau client",
            success: "Succès",
            deleted: "Supprimé",
            search_clients: "Rechercher des clients",
            selected: "Sélectionné(s)",
            delete: "Delete",
            toolbar: {
                country: "Pays",
                clear: "Effacer",
            },
            import_popup: {
                heading: "Importer des clients",
                subheading: "Sélectionnez votre fichier contenant la liste des clients à importer",
                validate_btn: "Valider",
                cancel_btn: "Annuler",
                drop_csv_here: "Déposez votre fichier CSV ici",
                import_alert: "Pour en savoir plus sur les étapes à suivre, veuillez lire cet article :",
            },
            not_found: {
                heading: "Enregistrer ici les informations de vos clients",
                sub_heading: "La vue détaillée regroupe l'ensemble des informations clés pour votre entreprise",
            },
            delete_popup: {
                heading: "Supprimer",
                description: "Voulez-vous vraiment supprimer {{count}} éléments ?",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
            },
            tabs: {
                all: "Tous",
                individual: "Particulier",
                enterprise: "Entreprise",
            },
            columns: {
                name: "Nom",
                company: "Entreprise",
                address: "Adresse",
                phone: "Téléphone",
                email: "Email",
                code: "Code client",
            }
        },
        view: {
            not_found: "Aucun client trouvé",
            deleted: "Client supprimé",
            view_client: "Voir le client",
            edit_btn: "Modifier",
            delete_btn: "Supprimer",
            create_btn: "Créer",
            create_menu: {
                invoice: "Facture",
                quote: "Devis",
            },
            delete_popup: {
                heading: "Attention",
                description: "Voulez-vous supprimer ce client ?",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
            },
            address: "Adresse",
            email: "Email",
            phone: "Téléphone",
            revenue_month: "Recettes du mois",
            revenue_year: "Recettes de l'année",
            note: {
                add_note_btn: "Ajouter une note",
                note_added: "Note ajoutée",
                note_updated: "Note mise à jour",
                saved_notes: "Notes",
                no_notes_yet: "Il n'y a pas encore de notes ici",
                create_first_note: "Créez votre première note",
                delete_btn: "Supprimer",
                edit_btn: "Edit",
                delete_popup: {
                    heading: "Attention",
                    description: "Voulez-vous vraiment supprimer la note ?",
                    cancel_btn: "Annuler",
                    delete_btn: "Supprimer",
                },
                model: {
                    error: {
                        title_require: "Titre requis",
                        content_require: "Contenu requis",
                    },
                    edit_note: "Note éditée",
                    add_note: "Ajouter une nouvelle note",
                    title: "Titre",
                    description: "Description",
                    cancel_btn: "Annuler",
                    save_btn: "Enregistrez votre note",
                }
            },
            docs: {
                doc_deleted: "Document supprimé",
                doc_delete_fail: "Échec de la suppression du document",
                doc_download_fail: "Échec du téléchargement du document",
                documents: "Documents",
                select_files: "Sélectionnez les fichiers",
            },
            invoices: {
                heading: "Factures",
                subheading: "Liste des factures émises pour ce client",
                sent: "Votre devis a été envoyée",
                columns: {
                    reference_date_labels: {
                        date: "Date",
                        issue_date: "Date d'émission",
                        paid_date: "Date de paiement",
                        execution_date: "Date de vente",
                        refund_date: "Date de remboursement",
                    },
                    date: "Date",
                    reference: "Référence",
                    client: "Client",
                    subject: "Objet",
                    total_exc_taxes: "Total HT",
                    total_inc_taxes: "Total TTC",
                    status: "Statut",
                    status_tag: {
                        pending: "En attente",
                        issued: "Émis",
                        paid: "Payé",
                        not_paid: "Non payé",
                        cancelled: "Annulé",
                        overdue: "En retard",
                        draft: "Brouillon",
                        accepted: "Accepté",
                        refused: "Refusé",
                        declined: "Refusé",
                        un_paid: "Non payé",
                        canceled: "Annulé",
                        closed: "Clôturé",
                        null: ""
                    },
                },
                send_modal: {
                    error: {
                        email: "L'email est requis",
                        title: "L'objet est requis",
                        body: "Le corps est requis",
                        invalid_emails: "Adresses e-mail invalides",
                    },
                    sent: "Envoyé",
                    subject: "Objet",
                    email: "Adresse e-mail",
                    multi_email_info: "Vous pouvez envoyer à plusieurs destinataires en séparant les e-mails par une virgule",
                    send: "Envoyer",
                    cancel_btn: "Annuler",
                    send_btn: "Envoyer",
                },
            },
            quotes: {
                sent: "Votre devis a été envoyée",
                heading: "Devis",
                subheading: "Liste des devis émis pour ce client",
                send_modal: {
                    error: {
                        email: "L'email est requis",
                        title: "L'objet est requis",
                        body: "Le corps est requis",
                        invalid_emails: "Adresses e-mail invalides",
                    },
                    sent: "Envoyé",
                    subject: "Objet",
                    email: "Adresse e-mail",
                    multi_email_info: "Vous pouvez envoyer à plusieurs destinataires en séparant les e-mails par une virgule",
                    send: "Envoyer",
                    cancel_btn: "Annuler",
                    send_btn: "Envoyer",
                },
                columns: {
                    date: "Date",
                    reference: "Référence",
                    client: "Client",
                    subject: "Objet",
                    total_exc_taxes: "Total HT",
                    total_inc_taxes: "Total TTC",
                    status: "Statut",
                    status_tag: {
                        pending: "En attente",
                        issued: "Émis",
                        paid: "Payé",
                        not_paid: "Non payé",
                        cancelled: "Annulé",
                        overdue: "En retard",
                        draft: "Brouillon",
                        accepted: "Accepté",
                        refused: "Refusé",
                        declined: "Refusé",
                        un_paid: "Non payé",
                        canceled: "Annulé",
                        closed: "Clôturé",
                        null: ""
                    },
                }
            }
        },
        edit: {
            create_client: "Créer un client",
            create_client_search: "Créer {{search}}",
            search_create_clients: "Rechercher ou créer un client",
            not_found: "Aucun client trouvé",
            edit: "Modifier le client",
            new: "Créer un nouveau client",
            created: "Client créé",
            updated: "Client mis à jour",
            cancel_btn: "Annuler",
            update_client_btn: "Mettre à jour le client",
            create_client_btn: "Créer un client",
            error: {
                country: "Le pays est requis",
                last_name: "Le nom est requis",
                company_name: "Nom de l'entreprise requis",
                address: "L'adresse est requise",
                city: "La ville est requise",
                zip: "Le code postal est requis",
            },
            client_type_labels: {
                individual: "Particulier",
                business: "Entreprise",
            },
            company_name_input_label: "Nom de l'entreprise",
            vat_number_input_label: "TVA intracommunautaire",
            registration_number_input_label: "SIREN",
            last_name_input_label: "Nom",
            first_name_input_label: "Prénom",
            email_input_label: "Email",
            telephone_input_label: "Téléphone",
            code_input_label: "Code client",
            address_input_label: "Adresse",
            additional_address_input_label: "Complément d'adresse",
            zip_input_label: "Code postal",
            city_input_label: "Ville",
            country_input_label: "Pays",
            choose_a_country: "Choisissez un pays",
            contact_company: "Contact dans l'entreprise",
            last_clients: "Derniers clients",
        }
    },
    suppliers: {
        nav_title: "Fournisseurs",
        list: {
            nav_title: "Fournisseurs",
            list_suppliers: "Liste des fournisseurs",
            new_supplier: "Nouveau fournisseur",
            deleted: "Supprimé",
            selected: "Sélectionné(s)",
            delete: "Supprimer",
            not_found: {
                heading: "Enregistrer ici les informations de vos fournisseurs",
                sub_heading: "La vue détaillée regroupe l'ensemble des informations clés pour votre entreprise",
            },
            search_suppliers: "Rechercher des fournisseurs",
            toolbar: {
                clear: "Effacer",
            },
            columns: {
                name: "Nom",
                address: "Adresse",
                phone: "Téléphone",
                email: "Email"
            },
            delete_popup: {
                heading: "Supprimer",
                description: "Voulez-vous vraiment supprimer {{count}} éléments ?",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
            },
        },
        view: {
            not_found: "Aucun fournisseur trouvé",
            deleted: "Fournisseur supprimé",
            view_supplier: "Voir le fournisseur",
            edit_btn: "Modifier",
            delete_btn: "Supprimer",
            create_expense_btn: "Créer une dépense",
            delete_popup: {
                heading: "Attention",
                description: "Voulez-vous supprimer ce fournisseur ?",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
            },
            address: "Adresse",
            email: "Email",
            phone: "Téléphone",
            revenue_month: "Dépenses du mois",
            revenue_year: "Dépenses de l'année",
            note: {
                add_note_btn: "Ajouter une nouvelle note",
                note_added: "Note ajoutée",
                note_updated: "Note mise à jour",
                saved_notes: "Notes",
                no_notes_yet: "Il n'y a pas encore de notes ici",
                create_first_note: "Créez votre première note",
                delete_btn: "Supprimer",
                edit_btn: "Edit",
                delete_popup: {
                    heading: "Attention",
                    description: "Voulez-vous vraiment supprimer la note ?",
                    cancel_btn: "Annuler",
                    delete_btn: "Supprimer",
                },
                model: {
                    error: {
                        title_require: "Titre requis",
                        content_require: "Contenu requis",
                    },
                    edit_note: "Note éditée",
                    add_note: "Ajouter une nouvelle note",
                    title: "Titre",
                    description: "Description",
                    cancel_btn: "Annuler",
                    save_btn: "Enregistrez votre note",
                }
            },
            docs: {
                doc_deleted: "Document supprimé",
                doc_delete_fail: "Échec de la suppression du document",
                doc_download_fail: "Échec du téléchargement du document",
                documents: "Documents",
                select_files: "Sélectionnez les fichiers",
            },
            expenses: {
                heading: "Dépenses",
                subheading: "Liste des dépenses enregistrées pour ce fournisseur",
                columns: {
                    date: "Date",
                    supplier: "Fournisseur",
                    label: "Libellé",
                    total_exc_taxes: "Total HT",
                    total_inc_taxes: "Total TTC",
                    payment_method: "Modes de paiement",
                    attachments: "Pièces jointes",
                    payment_methods: {
                        bank_transfer: "Virement",
                        cash: "Espèces",
                        check: "Chèque",
                        cesu: "Cesu",
                        direct_debit: "Prélèvement",
                        credit_card: "Carte bancaire",
                        other: "Autre",
                        paypal: "PayPal",
                        gift_card: "Carte cadeau",
                        null: ""
                    },
                    yes: "Oui",
                    no: "Non",
                }
            }
        },
        edit: {
            create_supplier: "Créer un fournisseur",
            create_supplier_search: "Créer {{search}}",
            search_create_suppliers: "Rechercher ou créer un fournisseur",
            not_found: "Aucun fournisseur trouvé",
            edit: "Modifier le fournisseur",
            new: "Créer un nouveau fournisseur",
            created: "Fournisseur créé",
            updated: "Fournisseur mis à jour",
            cancel_btn: "Annuler",
            create_btn: "Créer",
            update_supplier_btn: "Mettre à jour le fournisseur",
            create_supplier_btn: "Créer un fournisseur",
            error: {
                country: "Le pays est requis",
                company_name: "Nom de l'entreprise requis",
                invalid_emails: "Adresses e-mail invalides",
            },
            company_name_input_label: "Nom de l'entreprise",
            address_input_label: "Adresse",
            address_supplementary_input_label: "Complément d'adresse",
            zip_input_label: "Code postal",
            city_input_label: "Ville",
            country_input_label: "Pays",
            choose_a_country: "Choisissez un pays",
            contact_company: "Contact dans l'entreprise",
            last_name_input_label: "Nom",
            first_name_input_label: "Prénom",
            email_input_label: "Email",
            phone_input_label: "Téléphone",
            last_suppliers: "Derniers fournisseurs",
        }
    },
    articles: {
        nav_title: "Articles",
        list: {
            heading: "Articles",
            export_btn: "Exporter",
            import_btn: "Importer",
            new_btn: "Nouvel article",
            success: "Succès",
            selected: "Sélectionné(s)",
            sales: {
                goods: "Ventes de marchandises",
                services: "Prestations de services",
                other_services: "Autres prestations de services",
                disbursement_fees: "Débours",
                null: ""
            },
            import_popup: {
                import_articles: "Importer des articles",
                import_select_filters: "Sélectionnez votre fichier contenant la liste des éléments à importer",
                validate_btn: "Valider",
                cancel_btn: "Annuler",
                drop_csv_here: "Déposez votre fichier CSV ici",
                import_alert: "Pour en savoir plus sur les étapes à suivre, veuillez lire cet article :",
            },
            not_found: {
                sub_heading: "Pendant l'édition de vos factures, des suggestions d'articles apparaîtront afin de remplir automatiquement vos lignes",
                heading: "Enregistrez ici les articles que vous facturez régulièrement",
            },
            search_articles: "Rechercher un article...",
            deleted: "Supprimé",
            delete_popup: {
                heading: "Supprimer",
                description: "Voulez-vous vraiment supprimer {{count}} éléments ?",
                delete_btn: "Supprimer",
                cancel_btn: "Annuler"
            },
            columns: {
                reference: "Référence",
                title: "Titre",
                description: "Description",
                type: "Type",
                origin: "Origine",
                price: "Prix",
                maker: "Fabricant",
            },
            toolbar: {
                clear: "Effacer",
            },
        },
        edit: {
            updated: "Article mis à jour",
            created: "Article créé",
            deleted: "Supprimé",
            article: "Article",
            cancel_btn: "Annuler",
            delete_btn: "Supprimer",
            update: "Enregistrer",
            create: "Créer",
            delete_popup: {
                warning: "Attention",
                want_to_delete: "Voulez-vous supprimer l'article ?",
                cancel_btn: "Annuler",
                delete_btn: "Supprimer",
            },
            error: {
                not_found: "Aucun article trouvé",
                title: "Le titre est requis",
                price_min: "Prix minimum autorisé {{amount}}",
                price: "Le prix est requis",
                type: "Le type est requis",
                origin_country_code: "Le pays est requis",
            },
            info_section: {
                sku_input_label: "SKU",
                title_input_label: "Titre",
                maker_input_label: "Fabricant",
                description_input_label: "Description",
            },
            format_section: {
                price_format: "Format de prix",
                second_hand_article: "Article d'occasion",
                taxes_included_labels: {
                    vat_incl: "TTC",
                    vat_excl: "HT",
                },
                second_hand_good_labels: {
                    yes: "Oui",
                    no: "Non"
                }
            },
            vat_section: {
                sale_price_inc_tax_input_label: "Prix de vente TTC",
                price_exc_tax_input_label: "Prix de vente HT",
                vat_input_label: "TVA",
                no_results_found: "Aucun résultat trouvé.",
                type_input_label: "Type",
                country_dep_product_input_label: "Pays de départ du produit",
                choose_a_country: "Choisissez un pays",
                eco_participation_input_label: "Eco-participation",
                purchase_price_input_label: "Prix d'achat",
            }
        }
    },
    integration: {
        nav_title: "Intégrations",
        heading: "Intégrations",
        add_integration: "Ajouter une intégration",
        is_new: "Beta",
        paused: "Synchronisation désactivée",
        resumed: "Synchronisation activée",
        disconnected: "Service déconnecté",
        update: "Enregistrer",
        updated: "Configuration mise à jour",
        integrated_services: "Services connectés",
        edit_btn: "Modifier",
        sync_running: "La synchronisation est en cours",
        last_sync: "Dernière synchronisation effectuée {{time}}",
        finalize_sync: "Finaliser la synchronisation",
        waiting_sync: "En attente de la première synchronisation",
        find_services: "Rechercher des services...",
        order_id_not_found: "Le numéro indiqué n'a pas été trouvé",
        synchronization_running: "Synchronisation en cours",
        name: "Nom",
        name_updated: "Nom mis à jour",
        error: {
            activity: "L'activité est requise",
            must_allowed: "La valeur doit être supérieure ou égale à {{amount}}",
            tax_rate: "Le taux de TVA est requis",
            tax_rule: "Une règle fiscale est requise",
            sales_type: "Le type de vente est requis",
            country: "Le pays est requis",
            expense_type: "Le type de dépense est requis",
            invalid_emails: "Adresses e-mail invalides",
            synchronization_type: "Le type de synchronisation est requis",
        },
        edit_menu: {
            disconnect: "Déconnecter",
            configuration: "Configuration",
            reconnect: "Se reconnecter",
            force_sync: "Forcer la synchronisation",
            pause_sync: "Désactiver la synchronisation",
            resume_sync: "Activer la synchronisation",
        },
        resume_popup: {
            title: "Activer ce service ?",
            description: "Si vous synchronisez ce compte de paiement et qu'une boutique utilisant ce même compte est synchronisée, vos factures seront émises en double.",
            cancel_btn: "Annuler",
            enable_btn: "Activer",
        },
        no_record: {
            heading: "Intégrations",
            connect_info: "Connectez votre compte Bizyness avec d'autres services en ligne afin d'automatiser la création de vos factures.",
            begin_integration_btn: "Connecter un service",
        },
        connection_status: {
            connected: "Service connecté, finalisez la connexion pour commencer",
            refreshed: "Connexion actualisée",
            error: "Le service ne peut pas être connecté, contactez-nous pour plus de détails",
            error_paypal_requirements_email: "Connexion impossible ! Veuillez confirmer votre adresse e-mail sur https://www.paypal.com/businessprofile/settings afin de recevoir des paiements",
            error_paypal_requirements_receivable: "Connexion impossible ! Vous ne pouvez actuellement pas recevoir de paiements en raison de restrictions sur votre compte PayPal",
            error_webhook: "Le service ne peut pas être connecté car Bizyness est déjà installé sur celui-ci",
        },
        categories: {
            ecommerce: "E-commerce",
            automation: "Automatisation",
            payment_gateway: "Paiement",
        },
        descriptions: {
            wizishop: "Créez et envoyez automatiquement vos factures à partir de milliers de services en ligne",
            shopify: "Créez et envoyez automatiquement vos factures à partir de milliers de services en ligne",
            stripe: "Créez et envoyez automatiquement les factures pour vos paiements en ligne avec Stripe",
            paypal: "Créez et envoyez automatiquement les factures pour vos paiements en ligne avec PayPal",
            woocommerce: "Créez et envoyez automatiquement vos factures à partir de milliers de services en ligne",
            zapier: "Créez et envoyez automatiquement vos factures à partir de milliers de services en ligne",
        },
        status: {
            enabled: "Factures synchronisées",
            pending: "En attente",
            disabled: "Factures non synchronisées"
        },
        list: {
            all_integrations: "Tous les services",
            ecommerce: "E-commerce",
            automation: "Automatisation",
            payment_gateway: "Paiement",
        },
        woocommerce: {
            synchronization: "Pour connaître l'identifiant, ouvrez la commande dans votre interface d'administration et copiez le numéro de commande (sans le caractère #)",
            heading: "Connexion Woocommerce",
            description: "Indiquez l'URL de votre boutique pour installer l'application Bizyness",
            store_url_input_label: "URL de la boutique",
            if_error_pre: "Si vous avez une erreur lors de la synchronisation de votre boutique, vous pouvez ",
            if_error_link: "configurer manuellement la connexion.",
            follow_instruction: "Veuillez suivre les instructions sur ",
            this_page: "cette page ",
            fill_the_items: "et renseigner les éléments ci-dessous",
            customer_key_input_label: "Clé client",
            customer_secret_input_label: "Secret client",
            connect_btn: "Connexion",
            previous_btn: "Précédent",
            order_id: "Identifiant de commande",
            order_id_error: "L'identifiant de commande est requis",
            error: {
                store: "Le magasin est requis",
                invalid_url: "URL invalide",
                consumer_key: "La clé client est requise",
                consumer_secret: "Le secret du consommateur est requis",
            },
            disconnect_popup: {
                title: "Déconnexion",
                disconnect_notice: "Souhaitez-vous déconnecter cette boutique ?",
                cancel_btn: "Annuler",
                disconnect_btn: "Déconnecter",
            },
        },
        wizishop: {
            synchronization: "Indiquez le numéro de commande sans les chiffres '0' du début. Par exemple, indiquez 123 pour la commande 0000123",
            heading: "Connexion Wizishop",
            description: "Indiquez vos identifiants de connexion et choisissez la boutique à synchroniser",
            info_use: "NB : Ces informations sont utilisées pour connecter la 1ère fois votre boutique et ne sont pas conservées",
            identifier: "Identifiants WiziShop",
            shop_to_sync: "Boutique à synchroniser",
            order_id: "Numéro de commande",
            order_id_error: "Le numéro de commande est requis",
            password_input_label: "Mot de passe",
            email_input_label: "Email",
            login_btn: "Connexion",
            connect_btn: "Connexion",
            previous_btn: "Précédent",
            error: {
                invalid_email: "L'email est invalide",
                email: "L'e-mail est requis",
                password: "Le mot de passe est requis",
                store: "Le magasin est requis",
                can_not_connect: "Email ou mot de passe invalide",
            },
            disconnect_popup: {
                title: "Déconnexion",
                disconnect_notice: "Souhaitez-vous déconnecter cette boutique ?",
                cancel_btn: "Annuler",
                disconnect_btn: "Déconnecter",
            },
        },
        shopify: {
            synchronization: "Pour connaître l'identifiant, ouvrez la commande dans votre interface d'administration et copiez la dernière partie de l'URL (https://admin.shopify.com/store/maboutique/orders/XXXXXXXXXX)",
            heading: "Connexion Shopify",
            description: "Vous allez être redirigé vers le Store Shopify pour ajouter Bizyness à votre boutique. Le prix indiqué sur la page Shopify est indicatif, vous ne serez pas facturé par Shopify pour l'utilisation de l'application Bizyness.",
            connect_btn: "Connexion",
            previous_btn: "Précédent",
            order_id: "Identifiant de commande",
            order_id_error: "L'identifiant de commande est requis",
            disconnect_popup: {
                title: "Déconnexion",
                disconnect_notice: "Souhaitez-vous déconnecter cette boutique ?",
                cancel_btn: "Annuler",
                disconnect_btn: "Déconnecter",
            },
        },
        stripe: {
            synchronization: "Pour connaître l'identifiant, ouvrez le paiement dans votre interface d'administration Stripe et copiez le numéro de paiement commençant par 'ch_'",
            heading: "Connexion Stripe",
            description: "Cliquez sur le bouton ci-dessous pour associer ou créer votre compte Stripe",
            order_id: "Identifiant de paiement",
            order_id_error: "L'identifiant de paiement est requis",
            connect_btn: "Connexion",
            previous_btn: "Précédent",
            disconnect_popup: {
                title: "Déconnexion",
                disconnect_notice: "Souhaitez-vous déconnecter ce compte ?",
                cancel_btn: "Annuler",
                disconnect_btn: "Déconnecter",
            },
        },
        paypal: {
            synchronization: "Pour connaître l'identifiant, ouvrez le paiement dans votre interface d'administration PayPal et copiez le numéro de transaction",
            heading: "Connexion PayPal",
            description: "Cliquez sur le bouton ci-dessous pour associer ou créer votre compte PayPal",
            order_id: "Identifiant de paiement",
            order_id_error: "L'identifiant de paiement est requis",
            connect_btn: "Connexion",
            previous_btn: "Précédent",
            disconnect_popup: {
                title: "Déconnexion",
                disconnect_notice: "Souhaitez-vous déconnecter ce compte ?",
                cancel_btn: "Annuler",
                disconnect_btn: "Déconnecter",
            },
        },
        zapier: {
            heading: "Connexion Zapier",
            description: "Invitation requise",
            integration_info: "Cette intégration est actuellement en beta. Vous pouvez accéder au Zap Bizyness privé à cette adresse :",
            order_id: "Numéro de commande",
            order_id_error: "ID de commande requis",
            connect_btn: "Connexion",
            previous_btn: "Précédent",
            disconnect_popup: {
                title: "Déconnexion",
                disconnect_notice: "Souhaitez-vous déconnecter ce zapier ?",
                cancel_btn: "Annuler",
                disconnect_btn: "Déconnecter",
            },
        },
        amazon: {
            synchronization: "Indiquez le numéro de commande Amazon, par exemple 123-4567890-1234567",
            heading: "Connexion Amazon",
            description: "Sélectionnez votre marketplace pour connecter votre compte Amazon",
            marketplace: "Marché",
            order_id: "Numéro de commande",
            order_id_error: "ID de commande requis",
            connect_btn: "Connexion",
            previous_btn: "Précédent",
            error: {
                required: "Requis",
            },
            disconnect_popup: {
                title: "Déconnexion",
                disconnect_notice: "Souhaitez-vous déconnecter ce amazon ?",
                cancel_btn: "Annuler",
                disconnect_btn: "Déconnecter",
            },
        },
        force_synchronization: {
            synchronization_running: "Synchronisation en cours",
            force_sync: "Forcer la synchronisation",
            enter_valid_number: "Saisissez un identifiant valide pour forcer la synchronisation des factures manquantes",
            options: "Options",
            synchronize_issue: "Synchroniser et émettre les factures suivantes",
            cancel_btn: "Annuler",
            start_synchronization_btn: "Démarrer la synchronisation",
        },
        configuration_popup: {
            cancel_btn: "Annuler",
            save_changes_btn: "Sauvegarder les modifications",
            section_labels: {
                identity: "Activité",
                invoices: "Mode",
                vat: "TVA",
                options: "Options",
            },
            identity_section: {
                identity_info: "Les factures synchronisées seront associées à l'activité choisie. En séparant les activités au sein de votre entreprise vous pourrez avoir des rapports plus précis.",
                activity_input_label: "Activité",
                name: "Nom",
                email: "Email",
                store_name: "Nom du magasin",
                store_url: "URL du magasin",
            },
            invoice_section: {
                lang_inv_email: "Langue des factures et des e-mails",
                tooltips: {
                    lang_inv_email: "Traduire les factures et emails dans la langue du client ou non",
                    invoices_subject: "Indiquez l'objet qui sera affiché sur les factures",
                    products_metadata: "Indiquez les métadonnées de produit à afficher dans les lignes des factures",
                    include_sku: "Activez cette option pour inclure le SKU des produits dans la colonne 'REF' des factures",
                    include_notes: "Activez cette option pour inclure les notes/commentaires des commandes sur les factures",
                    include_pay_instruction: "Activez cette option pour ajouter les instructions de paiement (RIB ou autre) dans les factures",
                    payment_method_included: "Activez cette option pour inclure le mode de paiement utilisé sur les factures",
                    include_terms: "Activez cette option pour ajouter les conditions légales de paiement dans les factures",
                    sync_invoice_type: "Générer une facture",
                    sync_payment_type: "Paiement à l'importation",
                },
                sync_info: "Par défaut, Bizyness vous permet de générer des factures et des avoirs pour les commandes de votre boutique. Si vous préférez ne pas gérer les factures, vous pouvez choisir d'enregistrer uniquement les montants de vos commandes dans votre livre des recettes.",
                sync_invoice_type: "Générer les factures",
                sync_payment_type: "Importer les paiements",
                language_input_label: "Langue",
                products_meta_groups: {
                    standard_metafields: "Standard",
                    custom_metafields: "Personnalisé",
                },
                add_custom: "Ajouter '{{input}}'",
                products_metadata_input_label: "Métadonnées des produits",
                include_sku: "SKU",
                include_notes: "Notes",
                include_pay_instruction: "Instructions de paiement",
                payment_method_included: "Mode de paiement",
                include_terms: "Conditions de paiement",
                payment_delay_input_label: "Délai de paiement",
                lang_labels: {
                    use_customer_language: "Langue du client",
                    set_language: "Langue personnalisée",
                },
                payment_delay_labels: {
                    upon_invoice: "A réception",
                    upon_order: "A la commande",
                    upon_15_net: "15 jours nets",
                    upon_30_net: "30 jours nets",
                    upon_45_net: "45 jours nets",
                    upon_60_net: "60 jours nets",
                    upon_30_end: "30 jours nets fin de mois",
                    upon_45_end: "45 jours nets fin de mois",
                    upon_30_end_offset_10: "30 jours nets fin de mois, le 10",
                    upon_30_end_offset_20: "30 jours nets fin de mois, le 20",
                    none: "Aucun",
                }
            },
            vat_section: {
                vat_info: "Bizyness peut gérer automatiquement les taux de TVA de votre boutique en fonction de votre régime fiscal, de votre activité et de la localisation de vos clients. Cette option est recommandée pour la plupart des activités en ligne et prend en compte l'OSS/IOSS. Si vous bénéficiez de règles de taxation spécifiques ou que vous vendez des produits avec un taux de TVA réduit, Bizyness peut utiliser les taux de TVA définis dans votre boutique ou des règles de taxation spécifiques.",
                auto_vat: "TVA automatique",
                shop_vat: "TVA boutique",
                custom_vat: "TVA personnalisée",
                tva_exemption: "Exonération de TVA",
                exemption_text_input_label: "Message d'exonération",
                vat_rate_input_label: "Taux TVA",
                default_vat_rate_input_label: "Taux de TVA des articles",
                shipping_vat_rate_input_label: "Règle de TVA des frais de port",
                tooltips: {
                    auto_vat: "Bizyness gère automatiquement la TVA, sans prendre en compte le paramétrage de votre boutique",
                    shop_vat: "Bizyness utilise les taux de TVA définis dans votre boutique",
                    custom_vat: "Bizyness utilise les règles que vous définissez",
                    tva_exemption: "Activez cette option si toutes vos ventes sont exonérées de TVA",
                    tax_exemption_text: "Indiquez une mention d'exonération",
                    tax_rate: "Indiquez un taux de TVA fixe",
                    default_vat_rate: "Sélectionnez le taux de TVA par défaut des articles",
                    shipping_vat_rate: "Sélectionnez la méthode d'application du taux de TVA sur les frais de port",
                },
                shipping_rate: {
                    article_rate: "Taux des articles",
                    default_rate: "Taux standard",
                },
                taxes_options: {
                    yes: "Oui",
                    no: "Non"
                },
            },
            option_section: {
                type_of_sale_input_label: "Type de vente",
                departure_country_input_label: "Pays de départ des produits",
                choose_a_country: "Choisissez un pays",
                synchronization_mode_input_label: "Mode de synchronisation",
                order_to_exclude_input_label: "Commandes à exclure",
                no_tax_gift: "Carte cadeaux HT",
                send_invoice: "Envoyer les factures",
                synchronize_payments: "Synchroniser les paiements",
                expense_type_input_label: "Catégories de dépense",
                synchronize_articles: "Synchroniser les articles",
                price_format_input_label: "Format de prix",
                additional_recipient_invoices_input_label: "Destinataire supplémentaire des factures",
                multi_email_info: "Vous pouvez envoyer à plusieurs destinataires en séparant les e-mails par une virgule",
                tooltips: {
                    sales_type: "Indiquez le type que vous souhaitez appliquer sur vos factures",
                    synchronization_mode: "Indiquez à quel momment vous souhaitez émettre les factures",
                    order_to_exclude: "Indiquez les tags des commandes que vous ne souhaitez pas synchronise",
                    no_tax_gift: "Activez cette option si vous vendez des cartes cadeaux sans appliquer la TVA",
                    send_invoice: "Activez cette option pour envoyer automatiquement les factures émises à vos clients",
                    synchronize_payments: "Lorsque les commandes sont payées par l'intermédiaire d'une plateforme telle que Stripe ou PayPal, les frais de transaction sont ajoutées dans vos dépenses",
                    expense_type: "Indiquer la catégorie à utiliser pour enregistrer les frais bancaires",
                    synchronize_articles: "Activez cette option pour synchroniser les articles de votre boutique",
                    products_taxes_included: "Indiquez si le prix de vos articles inclus la TVA ou non",
                    additional_recipient: "Indiquez le destinataire, autre que le client, qui recevra toutes les factures émises",
                    products_tax_rate: "Indiquez le taux de TVA par défaut"
                },
                price_formats: {
                    false: "Prix HT",
                    true: "Prix TTC"
                },
                products_tax_rate: "Taux TVA",
                activity_types: {
                    goods: "Ventes de marchandises",
                    services: "Prestations de service commerciales ou artisanales (BIC)",
                    other_services: "Autres prestations de services ou activités libérales (BNC)",
                },
                synchronization_mode_labels: {
                    issued: "A la création de la commande",
                    paid: "Au paiement de la commande",
                    fulfilled: "A la livraison de la commande"
                },
            },
        },
        synchronize_popup: {
            next_btn: "Suivant",
            finalize_btn: "Finaliser",
            previous_btn: "Précédent",
            initialized: "Initialisé",
            section_labels: {
                identity: "Activité",
                invoices: "Mode",
                vat: "TVA",
                options: "Options",
                synchronization: "Synchronisation",
            },
            identity_section: {
                identity_info: "Les factures synchronisées seront associées à l'activité choisie. En séparant les activités au sein de votre entreprise vous pourrez avoir des rapports plus précis.",
                activity_input_label: "Activité",
                name: "Nom",
                email: "Email",
                store_name: "Nom du magasin",
                store_url: "URL du magasin",
            },
            invoice_section: {
                lang_inv_email: "Langue des factures et des e-mails",
                tooltips: {
                    lang_inv_email: "Traduire les factures et emails dans la langue du client ou non",
                    invoices_subject: "Indiquez l'objet qui sera affiché sur les factures",
                    products_metadata: "Indiquez les métadonnées de produit à afficher dans les lignes des factures",
                    include_sku: "Activez cette option pour inclure le SKU des produits dans la colonne 'REF' des factures",
                    include_notes: "Activez cette option pour inclure les notes/commentaires des commandes sur les factures",
                    include_pay_instruction: "Activez cette option pour ajouter les instructions de paiement (RIB ou autre) dans les factures",
                    payment_method_included: "Activez cette option pour inclure le mode de paiement utilisé sur les factures",
                    include_terms: "Activez cette option pour ajouter les conditions légales de paiement dans les factures",
                    sync_invoice_type: "Générer une facture",
                    sync_payment_type: "Paiement à l'importation",
                },
                sync_info: "Par défaut, Bizyness vous permet de générer des factures et des avoirs pour les commandes de votre boutique. Si vous préférez ne pas gérer les factures, vous pouvez choisir d'enregistrer uniquement les montants de vos commandes dans votre livre des recettes.",
                sync_invoice_type: "Générer les factures",
                sync_payment_type: "Importer les paiements",
                language_input_label: "Langue",
                products_meta_groups: {
                    standard_metafields: "Standard",
                    custom_metafields: "Personnalisé",
                },
                add_custom: "Ajouter '{{input}}'",
                products_metadata_input_label: "Métadonnées des produits",
                include_sku: "SKU",
                include_notes: "Notes",
                include_pay_instruction: "Instructions de paiement",
                payment_method_included: "Mode de paiement",
                include_terms: "Conditions de paiement",
                payment_delay_input_label: "Délai de paiement",
                lang_labels: {
                    use_customer_language: "Langue du client",
                    set_language: "Langue personnalisée",
                },
                payment_delay_labels: {
                    upon_invoice: "A réception",
                    upon_order: "A la commande",
                    upon_15_net: "15 jours nets",
                    upon_30_net: "30 jours nets",
                    upon_45_net: "45 jours nets",
                    upon_60_net: "60 jours nets",
                    upon_30_end: "30 jours nets fin de mois",
                    upon_45_end: "45 jours nets fin de mois",
                    upon_30_end_offset_10: "30 jours nets fin de mois, le 10",
                    upon_30_end_offset_20: "30 jours nets fin de mois, le 20",
                    none: "Aucun",
                }
            },
            vat_section: {
                vat_info: "Bizyness peut gérer automatiquement les taux de TVA de votre boutique en fonction de votre régime fiscal, de votre activité et de la localisation de vos clients. Cette option est recommandée pour la plupart des activités en ligne et prend en compte l'OSS/IOSS. Si vous bénéficiez de règles de taxation spécifiques ou que vous vendez des produits avec un taux de TVA réduit, Bizyness peut utiliser les taux de TVA définis dans votre boutique ou des règles de taxation spécifiques.",
                auto_vat: "TVA automatique",
                shop_vat: "TVA boutique",
                custom_vat: "TVA personnalisée",
                tva_exemption: "Exonération de TVA",
                exemption_text_input_label: "Message d'exonération",
                vat_rate_input_label: "Taux TVA",
                default_vat_rate_input_label: "Taux de TVA des articles",
                shipping_vat_rate_input_label: "Règle de TVA des frais de port",
                tooltips: {
                    auto_vat: "Bizyness gère automatiquement la TVA, sans prendre en compte le paramétrage de votre boutique",
                    shop_vat: "Bizyness utilise les taux de TVA définis dans votre boutique",
                    custom_vat: "Bizyness utilise les règles que vous définissez",
                    tva_exemption: "Activez cette option si toutes vos ventes sont exonérées de TVA",
                    tax_exemption_text: "Indiquez une mention d'exonération",
                    tax_rate: "Indiquez un taux de TVA fixe",
                    default_vat_rate: "Sélectionnez le taux de TVA par défaut des articles",
                    shipping_vat_rate: "Sélectionnez la méthode d'application du taux de TVA sur les frais de port",
                },
                shipping_rate: {
                    article_rate: "Taux des articles",
                    default_rate: "Taux standard",
                },
                taxes_options: {
                    yes: "Oui",
                    no: "Non"
                },
            },
            option_section: {
                type_of_sale_input_label: "Type de vente",
                departure_country_input_label: "Pays de départ des produits",
                choose_a_country: "Choisissez un pays",
                synchronization_mode_input_label: "Mode de synchronisation",
                order_to_exclude_input_label: "Commandes à exclure",
                no_tax_gift: "Carte cadeaux HT",
                send_invoice: "Envoyer les factures",
                synchronize_payments: "Synchroniser les paiements",
                expense_type_input_label: "Catégories de dépense",
                synchronize_articles: "Synchroniser les articles",
                price_format_input_label: "Format de prix",
                additional_recipient_invoices_input_label: "Destinataire supplémentaire des factures",
                multi_email_info: "Vous pouvez envoyer à plusieurs destinataires en séparant les e-mails par une virgule",
                tooltips: {
                    sales_type: "Indiquez le type que vous souhaitez appliquer sur vos factures",
                    synchronization_mode: "Indiquez à quel momment vous souhaitez émettre les factures",
                    order_to_exclude: "Indiquez les tags des commandes que vous ne souhaitez pas synchronise",
                    no_tax_gift: "Activez cette option si vous vendez des cartes cadeaux sans appliquer la TVA",
                    send_invoice: "Activez cette option pour envoyer automatiquement les factures émises à vos clients",
                    synchronize_payments: "Lorsque les commandes sont payées par l'intermédiaire d'une plateforme telle que Stripe ou PayPal, les frais de transaction sont ajoutées dans vos dépenses",
                    expense_type: "Indiquer la catégorie à utiliser pour enregistrer les frais bancaires",
                    synchronize_articles: "Activez cette option pour synchroniser les articles de votre boutique",
                    products_taxes_included: "Indiquez si le prix de vos articles inclus la TVA ou non",
                    additional_recipient: "Indiquez le destinataire, autre que le client, qui recevra toutes les factures émises",
                    products_tax_rate: "Indiquez le taux de TVA par défaut"
                },
                price_formats: {
                    false: "Prix HT",
                    true: "Prix TTC"
                },
                products_tax_rate: "Taux TVA",
                activity_types: {
                    goods: "Ventes de marchandises",
                    services: "Prestations de service commerciales ou artisanales (BIC)",
                    other_services: "Autres prestations de services ou activités libérales (BNC)",
                },
                synchronization_mode_labels: {
                    issued: "A la création de la commande",
                    paid: "Au paiement de la commande",
                    fulfilled: "A la livraison de la commande"
                },
            },
            sync_section: {
                synchronize_past_orders: "Voulez-vous synchroniser votre historique ?",
                past_orders_labels: {
                    yes: "Oui",
                    no: "Non"
                },
                synchronization_of_orders: "Synchronisation des commandes",
                fill_id_info: "Renseignez un identifiant de commande ci-dessous pour synchroniser votre boutique depuis cette commande jusqu'à aujourd'hui",
            }
        }
    },
    urssaf_report: {
        nav_title: "Déclarations URSSAF",
        heading: "Déclarations URSSAF",
        card_label: {
            revenue_of_distance_sales: "Chiffre d'affaires des ventes de marchandises :",
            revenue_of_service_deliveries: "Chiffre d'affaires des prestations de service commerciales ou artisanales :",
            revenue_of_other_services: "Chiffre d'affaires des autres prestations de services :",
            estimated_taxes: "Estimation des cotisations",
            provisional_declaration: "Déclaration provisoire de",
            declaration: "Déclaration",
            quarter: "Trimestre",
        }
    },
    income_expense_report: {
        nav_title: "Revenus/Dépenses",
        page_title: "Rapport des recettes et dépenses",
        heading: "Rapport des recettes et dépenses",
        refresh_results: "Actualiser",
        payments: "Paiements",
        invoices: "Factures",
        relative_date_filters: {
            today: "Aujourd'hui",
            yesterday: "Hier",
            this_week: "Cette semaine",
            last_week: "La semaine dernière",
            this_month: "Ce mois",
            last_month: "Le mois dernier",
            last_30_days: "Les 30 derniers jours",
            last_90_days: "90 derniers jours",
            last_12_months: "12 derniers mois",
            this_year: "Cette année",
            last_year: "L'année dernière",
        },
        filters: {
            more: "Plus de filtres",
            less: "Moins de filtres",
            from_input_label: "Du",
            to_input_label: "Au",
            filter_by: "Filtrer par",
            activity_input_label: "Activité",
            loading: "Chargement...",
            no_activity_found: "Aucune activité trouvée",
        },
        reference_date_options: {
            date_of_issue: "Date d'émission",
            date_of_sale: "Date de vente",
            payment_date: "Date de paiement",
        },
        error: {
            date: "La date est requise",
            min_start_date: "La date de fin doit être postérieure à la date de début",
        },
        evaluation: {
            net_margin: "Bénéfices",
            incomes: "Recettes",
            expenses: "Dépenses",
            evolution_profits: "Évolution des bénéfices",
        },
        sales: {
            merchandise_sales: "Ventes de marchandises",
            comm_or_artisanal_ser: "Prestations de services (BIC)",
            other_services: "Autres prestations de services (BNC)",
        },
        distribution: {
            by_country: "Répartition des recettes",
            by_expense: "Répartition des dépenses",
            no_data: "Il n'y a pas de données à afficher",
        }
    },
    vat_report: {
        nav_title: "TVA locale",
        heading: "Déclaration de TVA",
        refresh_results: "Actualiser",
        taxable_trans: "Opérations imposables",
        total_base_exc_tax: "Total base hors taxes",
        rate_percent: "Taux {{rate}} % : ",
        total_vat_collected: "TVA totale collectée",
        deductible_vat: "TVA déductible",
        total_deductible_vat: "Total TVA déductible",
        non_taxable_trans: "Transactions non imposables",
        total_without_taxes: "Total hors taxes",
        filters: {
            more: "Plus de filtres",
            less: "Moins de filtres",
            from_input_label: "Du",
            to_input_label: "Au",
            filter_by: "Filtrer par",
            activity_input_label: "Activité",
            loading: "Chargement...",
            no_activity_found: "Aucune activité trouvée",
        },
        relative_date_filters: {
            today: "Aujourd'hui",
            yesterday: "Hier",
            this_week: "Cette semaine",
            last_week: "La semaine dernière",
            this_month: "Ce mois",
            last_month: "Le mois dernier",
            last_30_days: "Les 30 derniers jours",
            last_90_days: "90 derniers jours",
            last_12_months: "12 derniers mois",
            this_year: "Cette année",
            last_year: "L'année dernière",
        },
        error: {
            date: "La date est requise",
            min_start_date: "La date de fin doit être postérieure à la date de début",
        },
    },
    goods_report: {
        nav_title: "TVA sur marge",
        heading: "Rapport de TVA sur marge",
        refresh_results: "Actualiser",
        taxable_trans: "Opérations imposables",
        total_margin: "Total marge",
        total_taxes_collected: "Total TVA collectée",
        reference_date_options: {
            date_of_issue: "Date d'émission",
            date_of_sale: "Date de vente",
            payment_date: "Date de paiement",
        },
        filters: {
            more: "Plus de filtres",
            less: "Moins de filtres",
            from_input_label: "Du",
            to_input_label: "Au",
            filter_by: "Filtrer par",
            activity_input_label: "Activité",
            loading: "Chargement...",
            no_activity_found: "Aucune activité trouvée",
        },
        relative_date_filters: {
            today: "Aujourd'hui",
            yesterday: "Hier",
            this_week: "Cette semaine",
            last_week: "La semaine dernière",
            this_month: "Ce mois",
            last_month: "Le mois dernier",
            last_30_days: "Les 30 derniers jours",
            last_90_days: "90 derniers jours",
            last_12_months: "12 derniers mois",
            this_year: "Cette année",
            last_year: "L'année dernière",
        },
        error: {
            date: "La date est requise",
            min_start_date: "La date de fin doit être postérieure à la date de début",
        },
    },
    oss_report: {
        nav_title: "OSS/IOSS",
        heading: "Rapport IOSS",
        refresh_results: "Actualiser",
        list_of_ioss: "Liste des opérations IOSS",
        services_sales: "Vente de services",
        sale_of_goods: "Vente de biens",
        list_of_oss: "Liste des opérations OSS",
        download: "Télécharger",
        see_details: "Voir les détails",
        country: "Pays",
        vat_rate: "Taux TVA",
        total_HT: "Total HT",
        total_vat: "Total TVA",
        no_data: "Il n'y a pas de données à afficher",
        report_sent_mailbox: "Le rapport sera envoyé à votre boîte aux lettres dans quelques minutes",
        filters: {
            more: "Plus de filtres",
            less: "Moins de filtres",
            from_input_label: "Du",
            to_input_label: "Au",
            filter_by: "Filtrer par",
            activity_input_label: "Activité",
            loading: "Chargement...",
            no_activity_found: "Aucune activité trouvée",
        },
        relative_date_filters: {
            today: "Aujourd'hui",
            yesterday: "Hier",
            this_week: "Cette semaine",
            last_week: "La semaine dernière",
            this_month: "Ce mois",
            last_month: "Le mois dernier",
            last_30_days: "Les 30 derniers jours",
            last_90_days: "90 derniers jours",
            last_12_months: "12 derniers mois",
            this_year: "Cette année",
            last_year: "L'année dernière",
        },
        error: {
            date: "La date est requise",
            min_start_date: "La date de fin doit être postérieure à la date de début",
        },
    },
    accounting_report: {
        nav_title: "Comptable",
        heading: "Rapport comptable",
        refresh_results: "Actualiser",
        download: "Télécharger",
        reference_date_options: {
            date_of_issue: "Date d'émission",
            date_of_sale: "Date de vente",
            payment_date: "Date de paiement",
        },
        filters: {
            more: "Plus de filtres",
            less: "Moins de filtres",
            from_input_label: "Du",
            to_input_label: "Au",
            filter_by: "Filtrer par",
            activity_input_label: "Activité",
            loading: "Chargement...",
            no_activity_found: "Aucune activité trouvée",
        },
        relative_date_filters: {
            today: "Aujourd'hui",
            yesterday: "Hier",
            this_week: "Cette semaine",
            last_week: "La semaine dernière",
            this_month: "Ce mois",
            last_month: "Le mois dernier",
            last_30_days: "Les 30 derniers jours",
            last_90_days: "90 derniers jours",
            last_12_months: "12 derniers mois",
            this_year: "Cette année",
            last_year: "L'année dernière",
        },
        error: {
            date: "La date est requise",
            min_start_date: "La date de fin doit être postérieure à la date de début",
            required: "Requis",
        },
        configuration: {
            unlink_trans: "Dissocier les opérations",
            property: "Propriété",
            value: "Valeur",
            client_country_extra: {
                _europe: "Europe",
                _not_europe: "Hors Europe",
            },
            log_code_input_label: "Code journal",
            journal_input_label: "Libellé journal",
            account_number_input_label: "Numéro compte",
            account_input_label: "Libellé compte",
            add_condition_btn: "Ajouter une condition",
            delete_line_btn: "Supprimer la ligne",
            add_line_btn: "Ajouter une ligne",
            total_including_taxes: {
                heading: "Total TTC",
                sub_heading: "Montant total TTC de la facture",
            },
            goods_taxes_basis: {
                heading: "Total HT - Ventes de marchandises",
                sub_heading: "Montant Total Base HT",
            },
            services_taxes_basis: {
                heading: "Total HT - Prestations de services (BIC)",
                sub_heading: "Montant Total Base HT",
            },
            other_services_taxes_basis: {
                heading: "Total HT - Prestations de services (BNC)",
                sub_heading: "Montant Total Base HT",
            },
            shipping: {
                heading: "Frais de livraison",
                sub_heading: "Montant HT des frais de livraison",
            },
            taxes: {
                heading: "Total TVA",
                sub_heading: "Montant total de la TVA",
            },
            second_hand_goods_margin: {
                heading: "Articles d'occasion (Base HT)",
                sub_heading: "Montant Total Base HT",
            },
            second_hand_goods_taxes: {
                heading: "Articles d'occasion (TVA sur la marge)",
                sub_heading: "Montant total de la TVA",
            },
            gap: {
                heading: "Écart d'arrondis",
                sub_heading: "Montant de l'écart de l'arrondi du Total Base HT et de la TVA",
            },
        },
        payment_methods: {
            bank_transfer: "Virement",
            cash: "Espèces",
            check: "Chèque",
            cesu: "Cesu",
            direct_debit: "Prélèvement",
            credit_card: "Carte bancaire",
            other: "Autre",
            paypal: "PayPal",
            gift_card: "Carte cadeau",
            null: ""
        },
        client_types: {
            individual: "Particulier",
            company: "Entreprise"
        },
        unlink_labels: {
            yes: "Oui",
            no: "Non"
        },
        property_label: {
            vat_rate: "Taux de TVA",
            client_country: "Lieu de vente",
            payment_mode: "Mode de paiement",
            client_type: "Type de client",
        },
    },
    company_setting: {
        nav_title: "Entreprise",
        updated: "Mis à jour avec succès",
        information_heading: "Informations",
        save_btn: "Enregistrer",
        company_name_input_label: "Raison sociale",
        address_input_label: "Adresse",
        address_supplementary_input_label: "Complément d'adresse",
        zip_input_label: "Code postal",
        city_input_label: "Ville",
        country_input_label: "Pays",
        registration_type_input_label: "Immatriculation",
        company_identifier_input_label: "Siren",
        vat_number_input_label: "TVA intracommunautaire",
        share_capital_input_label: "Capital social",
        choose_a_country: "Choisissez un pays",
        registration_types: {
            rcs: "RCS",
            exempted: "Dispensé",
            rm: "RM",
            rseirl: "RSEIRL",
            rcs_rm: "Double immatriculation RCS/RM"
        },
        registration_type_labels: {
            rcs: "Ville d'immatriculation RCS",
            rm: "Numéro du département d'immatriculation RM",
            rseirl: "Ville d'immatriculation RSEIRL",
            rcs_rm: "Ville d'immatriculation RCS et Numéro du département d'immatriculation RM (séparés par une virgule)"
        },
        warranty: "Assurance professionnelle",
        insurance_company_name_input_label: "Coordonnées de l'assureur",
        insurance_geographic_coverage_input_label: "Couverture géographique",
        second_hand_goods_selling: "Vente de biens d'occasion",
        tax_payment_debit_based: "Option pour le paiement de la taxe d'après les débits",
        frequency_contributions: "Périodicité des cotisations URSSAF",
        creation_date_input_label: "Date de création",
        income_tax_discharged: "Versement libératoire de l'impôt sur le revenu",
        beneficiary: "Bénéficiaire de l'ACRE",
        error: {
            company: "Le nom de l'entreprise est requis",
            country: "Le pays est requis",
            address: "L'adresse est requise",
            city: "La ville est requise",
            must_allowed: "La valeur doit être supérieure ou égale à {{amount}}",
            share_capital: "Capital social requis",
            invalid_date: "La date n'est pas valide",
        },
        insurance_labels: {
            no_warranty: "Dispensé",
            defect_insurance: "Assurance décennale",
        },
        goods_options: {
            yes: "Oui",
            no: "Non"
        },
        debit_based_options: {
            yes: "Oui",
            no: "Non"
        },
        beneficiary_labels: {
            yes: "Oui",
            no: "Non"
        },
        tax_discharge_labels: {
            yes: "Oui",
            no: "Non"
        },
        distribution_labels: {
            monthly: "Mensuelle",
            quarterly: "Trimestriel",
        },
    },
    vat_setting: {
        nav_title: "TVA",
        updated: "Mis à jour avec succès",
        status_of_company: "Statut de votre entreprise",
        save_btn: "Enregistrer",
        eligible_to_vat: "Êtes-vous éligible à la TVA ?",
        choose_the_date: "Choisissez la date",
        liability_date_input_label: "Date d'éligibilité",
        distance_sales: "Ventes à distance en Europe",
        alert_sales: "Lorsque vous réalisez dans l'année plus de 10 000 € de ventes à distance et de services électroniques en Europe, vous devez appliquer le taux de TVA de l'autre Etat membre. Si vous avez déjà dépassé ce seuil ou avez opté pour l'OSS, vous pouvez l'indiquer ci-dessous.",
        oss_registered: "Enregistré OSS",
        registeration_date_input_label: "Date d'inscription",
        threshold_exceed: "Seuil des 10000 € dépassé",
        exceeding_date_input_label: "Date de dépassement",
        total_sales_in: "Montant total des ventes en Europe",
        imports_in: "Importations en Europe",
        alert_imports: "Si vous expédiez des biens à partir de pays tiers à l'Union européenne, à destination de clients résidant en Europe, pour des montants inférieurs à 150€, vous pouvez souscrire à l'IOSS afin de simplifier les démarches de paiement de la TVA.",
        ioss_registered: "Enregistré IOSS",
        registeration_date_import_input_label: "Date d'inscription",
        error: {
            date: "La date est requise",
            required: "Requis",
            invalid_date: "La date n'est pas valide",
            update: "Échec de la mise à jour",
        },
        vat_options: {
            yes: "Oui",
            no: "Non"
        },
        oss_registered_labels: {
            yes: "Oui",
            no: "Non"
        },
        threshold_exceed_labels: {
            yes: "Oui",
            no: "Non"
        },
        ioss_registered_labels: {
            yes: "Oui",
            no: "Non"
        },
        date_labels: {
            since_creation_date: "Depuis la date de création",
            another_date: "Depuis une autre date"
        },
    },
    activity_setting: {
        nav_title: "Activités",
        list: {
            title: "Activités",
            new_activity_btn: "Nouvelle activité",
            not_found: {
                img_alt: "Informations sur les activités ici",
                note: "Informations sur les activités ici",
                extra_note: "Les factures seront automatiquement émises avec les information indiquées, à la date programmée",
            },
            deleted: "Supprimé",
            duplicated: "Dupliqué",
            default_label: "Défaut",
            edit_menu: {
                edit: "Modifier",
                duplicate: "Dupliquer",
                delete: "Supprimer"
            },
            duplicate_popup: {
                title: "Dupliquer l'activité",
                submit_text: "Dupliquer l'activité",
                name_input_label: "Nom",
                cancel_btn: "Annuler",
                error: {
                    name: "Le nom est requis",
                }
            },
            delete_popup: {
                title: "Attention",
                subtitle: "Voulez-vous vraiment supprimer l'activité ?",
                delete_btn: "Effacer",
                cancel_btn: "Annuler",
            }
        },
        edit: {
            title: "Paramètres d'activité",
            cancel_btn: "Annuler",
            save_btn: "Enregistrer",
            created: "Activité créée",
            updated: "Activité mise à jour",
            general: {
                title: "Général",
                activity_name: "Nom de l'activité",
                activity_name_input_label: "Modifier le nom de l'activité",
                delete_btn: "Effacer",
                identity: "Identité",
                trade_name_input_label: "Nom commercial",
                email_input_label: "Email",
                languages: {
                    french: "Français",
                    english: "Anglais",
                    german: "Allemand",
                    italian: "Italien",
                    spanish: "Espagnol"
                },
                footer_input_label: "Pied de page des factures",
                payment_methods: {
                    bank_transfer: "Virement",
                    cash: "Espèces",
                    check: "Chèque",
                    cesu: "Cesu",
                    direct_debit: "Prélèvement",
                    credit_card: "Carte bancaire",
                    other: "Autre",
                    paypal: "PayPal",
                    gift_card: "Carte cadeau",
                    null: ""
                },
                payment_delay_options: {
                    upon_invoice: "A réception",
                    upon_order: "A la commande",
                    upon_15_net: "15 jours nets",
                    upon_30_net: "30 jours nets",
                    upon_45_net: "45 jours nets",
                    upon_60_net: "60 jours nets",
                    upon_30_end: "30 jours nets fin de mois",
                    upon_45_end: "45 jours nets fin de mois",
                    upon_30_end_offset_10: "30 jours nets fin de mois, le 10",
                    upon_30_end_offset_20: "30 jours nets fin de mois, le 20",
                    none: "Aucun",
                },
                price_formats: {
                    vat_incl: "TTC",
                    vat_excl: "HT",
                },
                payment_info: "Informations de paiement",
                late_penalty_rate_input_label: "Taux des intérêts de retard",
                rebate_rate_input_label: "Taux d'escompte",
                payment_terms_input_label: "Modalités de paiement",
                payment_method_input_label: "Modes de paiement",
                bank_transfer: "Virement bancaire",
                iban_input_label: "IBAN",
                bic_input_label: "BIC",
                payment_email_input_label: "Email",
                bank_input_label: "Banque",
                default_values: "Valeurs par défaut",
                sales_type_input_label: "Type de vente",
                price_format_input_label: "Format de prix",
                vat_input_label: "TVA",
                no_results_found: "Aucun résultat trouvé.",
                condition_notes_input_label: "Conditions, notes, plan de paiement...",
                term_and_conditions: "Documents contractuels",
                signature: "Signature",
                primary_color: "Couleur primaire",
                select_color_input_label: "Choisissez la couleur",
                loading_pdf: "Chargement du PDF",
                of: "de",
            },
            email: {
                title: "Email",
                common_heading: "Commune",
                include_footer: "Inclure le pied de page",
                include_title: "Inclure le titre",
                include_logo: "Inclure le logo",
                insert_tag: "Insérez une balise :",
                invoices: "Factures",
                credit_notes: "Avoirs",
                quotes: "Devis",
                delivery_notes: "Bons de livraison",
                title_input_label: "Titre",
                alignment: {
                    top_left_input_label: "En haut à gauche",
                    top_right_input_label: "En haut à droite",
                    bottom_left_input_label: "En bas à gauche",
                    bottom_right_input_label: "En bas à droite",
                },
                languages: {
                    french: "Français",
                    english: "Anglais",
                    german: "Allemand",
                    italian: "Italien",
                    spanish: "Espagnol"
                },
                tags: {
                    your_company_name: "Nom de votre entreprise",
                    your_company_email: "Email de votre entreprise",
                    invoice_number: "Numéro de facture",
                    invoice_amount: "Montant de la facture",
                    order_number: "Numéro de commande",
                }
            },
            error: {
                file_upload_fail: "file_upload_fail ",
                not_found: "Aucune activité trouvée",
                name: "Le nom est requis",
                must_allowed: "La valeur doit être supérieure ou égale à {{amount}}",
                late_penalty_rate: "Le taux de pénalité est requis",
                rebate_rate: "Le taux de remboursement est requis",
                payment_term: "Le délai de paiement est requis",
                template_name: "Le modèle est requis",
                template_color: "La couleur du modèle est requise",
                sales_type: "Le type de vente est requis",
                tax_rate: "Le taux de TVA est requis",
                title: "Le titre est requis",
                body: "Le corps est requis",
                email: "E-mail invalide"
            },
        }
    },
    notification: {
        nav_title: "Notifications",
        heading: "Notifications",
        description: "Description",
        selected: "Sélectionné(s)",
        deleted: "Supprimé",
        see_more: "Voir détail",
        cancel_btn: "Annuler",
        delete_btn: "Effacer",
        cancel_view_btn: "Annuler",
        warning: "Attention",
        ok: "OK",
        notification: "Notification",
        notifications: "Notifications",
        delete_notifications: "Supprimer les notifications ?",
        confirm_delete: "Cette opération est irréversible !",
        store: {
            disabled: {
                title: "Synchronisation suspendue",
                message: "La synchronisation de votre boutique {{store}} a été suspendue.",
                details: "La synchronisation a été automatiquement suspendue en raison de la désactivation de votre compte ou de votre boutique. Veuillez vous rendre dans le menu 'Intégrations' pour réactiver la synchronisation."
            },
            error: {
                title: "Erreur de synchronisation",
                message: "Une erreur s'est produite lors de la synchronisation de votre boutique {{store}}.",
                details: "Votre boutique ne peut pas être synchronisée. Veuillez vérifier les paramètres de connexion et nous contacter si l'erreur persiste."
            }
        },
        order: {
            error: {
                title: "Erreur de synchronisation de commande",
                message: "Une erreur s'est produite lors de la synchronisation de la commande {{order}} de votre boutique {{store}}.",
                details: "La facture correspondante à la commande {{order}} n'a pu être émise. Merci de nous contacter afin d'en savoir plus.",
                refund: {
                    title: "Erreur de synchronisation de remboursement",
                    message: "Une erreur s'est produite lors de la synchronisation de la commande {{order}} de votre boutique {{store}}.",
                    details: "L'avoir correspondant à la commande {{order}} n'a pu être émis. Merci de nous contacter afin d'en savoir plus."
                }
            },
            unsupported: {
                title: "Synchronisation de commande impossible",
                message: "La commande {{order}} de votre boutique {{store}} ne peut pas être synchronisée.",
                details: "La facture de la commande {{order}} n'a pas pu être finalisée, un brouillon a été créé afin que vous puissiez valider manuellement la facture.",
                refund: {
                    title: "Synchronisation de remboursement impossible",
                    message: "La commande {{order}} de votre boutique {{store}} ne peut pas être synchronisée.",
                    details: "L'avoir de la commande {{order}} n'a pas pu être finalisé, un brouillon a été créé afin que vous puissiez valider manuellement l'avoir."
                },
                currency: {
                    title: "Synchronisation de commande impossible",
                    message: "La devise de la commande {{order}} de votre boutique {{store}} n'est pas supportée.",
                    details: "La facture correspondante à la commande {{order}} n'a pu être émise car la devise de cette commande n'est pas supportée."
                },
                shopify: {
                    outstanding: {
                        positive: {
                            title: "Synchronisation impossible",
                            message: "La commande {{order}} de votre boutique {{store}} a un paiement en attente.",
                            details: "La facture de la commande {{order}} n'a pas pu être finalisée car un paiement est en attente, veuillez vérifier la commande dans votre boutique Shopify. Un brouillon a été créé afin que vous puissiez valider manuellement la facture."
                        },
                        negative: {
                            title: "Synchronisation impossible",
                            message: "La commande {{order}} de votre boutique {{store}} a un remboursement en attente.",
                            details: "La facture de la commande {{order}} n'a pas pu être finalisée car un remboursement est en attente, veuillez vérifier la commande dans votre boutique Shopify. Un brouillon a été créé afin que vous puissiez valider manuellement la facture."
                        }
                    }
                }
            }
        },
        bank: {
            error: {
                title: "Mise à jour requise",
                message: "La synchronisation avec votre banque {{bank}} doit être mise à jour.",
                details: "Une action est requise afin de rétablir la connexion avec votre banque. Veuillez vous rendre dans le menu 'Banques' et suivre les indications."
            }
        },
        unread_popup: {
            heading: "Attention",
            description: "Vous avez des notifications en attente",
            see_more_btn: "Voir détail",
        },
    },
    page_404: {
        not_found: "404 Page non trouvée",
        sorry_not_found: "Désolé, page introuvable !",
        sorry_could_not_find: "Désolé, nous n'avons pas trouvé la page que vous recherchez. Peut-être avez-vous mal tapé l'URL ? Assurez-vous de vérifier votre orthographe.",
        go_home: "Retour au tableau de bord",
    },
    language: {
        fr: "Français",
        en: "Anglais",
        cimode: "CI Mode"
    },
    all_language_labels: {
        customer_language: "Langue du client",
        french: "Français",
        english: "Anglais",
        german: "Allemand",
        italian: "Italien",
        spanish: "Espagnol"
    },
    templates: {
        simple: "Simple",
        london: "London",
        paris: "Paris",
        berlin: "Berlin"
    },
    common: {
        upload_photo: "Télécharger un logo",
        something_wrong: "Une erreur s'est produite",
        drop_or_select_file: "Déposer ou sélectionner un fichier",
        drop_files_here: "Déposez des fichiers ici ou cliquez pour",
        browse: "parcourir",
        through_your_machine: "votre ordinateur",
        write_something_awesome: "Écrivez votre message...",
        loading: "Chargement...",
        update_photo: "Mettre à jour la photo",
        remove_all: "Tout supprimer",
        forbidden: "Interdit",
        go_home: "Retour au tableau de bord",
        made_by: "Conçu par Bizyness",
        permission_denied: "Vous n'avez pas l'autorisation",
    },
    public: {
        invoice: {
            invalid_token: "Jeton invalide",
            days: "Jours",
            delay_before_due_date: "Échéance",
            status_of_payments: "Paiements",
            payment_method: "Payer la facture",
            attachments: "Pièces jointes",
            connect_bizyness: "Contact avec Bizyness",
            download_pdf: "Télécharger la facture (PDF)",
            total_amount: "Montant total:",
            sent_on: "Envoyée le :",
            invoice: "FACTURE",
            payment_methods: {
                credit_card: "Carte bancaire",
                paypal: "PayPal",
            },
            success_popup: {
                heading: "Paiement accepté",
                description: "Votre paiement est en cours de traitement, il sera validé d'ici quelques minutes.",
                okay_btn: "D'accord",
            },
            status_tag: {
                paid: "Payée",
                not_paid: "Non payée",
                cancelled: "Annulée",
                overdue: "En retard",
                null: ""
            },
            pdf: {
                loading_pdf: "Chargement du PDF",
                of: "de",
            },
            warning_overdue_days: "Attention ! Le paiement est en retard de {{days}} jours !",
            warning_overdue_day: "Attention ! Le paiement est en retard de {{day}} jour !",
            overdue: "En retard"
        },
        quote: {
            invalid_token: "Jeton invalide",
            days: "Jours",
            delay_before_due_date: "Échéance",
            status_of_payments: "Paiements",
            payment_method: "Mode de paiement",
            attachments: "Pièces jointes",
            connect_bizyness: "Contact avec Bizyness",
            download_pdf: "Télécharger le devis (PDF)",
            total_amount: "Montant total:",
            sent_on: "Envoyé le :",
            invoice: "FACTURE",
            accept_btn: "Accepter",
            refuse_btn: "Refuser",
            accepted: "Accepté",
            refused: "Refusé",
            notes_heading: "Remarques",
            write_comment: "Écrire un commentaire",
            send_btn: "Envoyer",
            sent_alert: "Note envoyée",
            status_tag: {
                pending: "En attente",
                accepted: "Accepté",
                declined: "Refusé",
                null: ""
            },
            pdf: {
                loading_pdf: "Chargement du PDF",
                of: "de",
            },
            accept_popup: {
                heading: "Attention",
                description: "Êtes-vous sûr de vouloir accepter ?",
                cancel_btn: "Non",
                confirm_btn: "Oui",
            },
            refuse_popup: {
                heading: "Attention",
                description: "Êtes-vous sûr de vouloir refuser ?",
                cancel_btn: "Non",
                confirm_btn: "Oui",
            }
        }
    }
};

export default fr;
