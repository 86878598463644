// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps, Tooltip, useTheme } from '@mui/material';
// theme icon
import { InfoIcon } from 'src/theme/overrides/components/CustomIcons';

// ----------------------------------------------------------------------

type IProps = {
    name: string;
    labelTooltip?: string;
    children: React.ReactNode;
    label?: string;
};

type Props = IProps & TextFieldProps;

export default function RHFSelect({ name, children, label = "", labelTooltip = "", ...other }: Props) {
    const { control } = useFormContext();

    const theme = useTheme();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <TextField
                    {...field}
                    select
                    label={
                        (label || labelTooltip) &&
                        <div style={{ display: 'flex' }}>
                            <div>
                                {label}
                            </div>
                            {labelTooltip &&
                                <div style={{ display: 'inline-block', marginLeft: '4px' }}>
                                    <Tooltip title={labelTooltip} placement="top" style={{ zIndex: 1000 }}>
                                        <span>
                                            <InfoIcon sx={{ width: 19, height: 19, color: theme.palette.grey[500] }} />
                                        </span>
                                    </Tooltip>
                                </div>}
                        </div>
                    }
                    fullWidth
                    SelectProps={{ native: true }}
                    error={!!error}
                    helperText={error?.message}
                    {...other}
                    onChange={(event) => {
                        field.onChange(event);
                        let { onChange } = { ...other }
                        onChange?.(event);
                    }}
                // InputLabelProps={{ style: { pointerEvents: "auto" } }}
                >
                    {children}
                </TextField>
            )}
        />
    );
}
